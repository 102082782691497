import { useQuery } from '@tanstack/vue-query';

export default function () {
	const query = gql`
		query getIdeasFromMe {
			ideasFromMe {
				id
				title
				body
				statusTypeId
				statusType {
					nameResourceId
				}
				createdOn
			}
		}
	`;

	const { data, ...args } = useQuery({
		queryKey: ['ideasFromMe'],
		queryFn: useGQLRequest(query),
		select: (data) => data.ideasFromMe ?? [],
	});

	return {
		ideasFromMe: data,
		...args,
	};
}
