<template>
	<span
		class="inline-flex items-center gap-x-1.5 rounded-full font-semibold"
		:class="{
			/* THEMES */

			// Info
			'bg-blue-100 text-primary-500': theme === 'info',

			// Success
			'bg-green-100 text-green-600 ': theme === 'success',

			// Danger
			'bg-red-100 text-red-600  ': theme === 'danger',

			// Neutral
			' bg-slate-100 text-slate-400': theme === 'neutral',

			// Warning
			'bg-yellow-200 text-yellow-700': theme === 'warning',

			// Dark
			'bg-slate-700 text-slate-50': theme === 'dark',

			// Light
			'border border-solid border-slate-200 bg-white text-slate-700':
				theme === 'light',

			// Size: Default
			'px-2.5 py-1 text-sm ': size === 'default',

			// Size: Small
			'px-2.5 py-0 text-xs': size === 'small',
		}"
	>
		<svg
			v-if="dot"
			class="h-1.5 w-1.5"
			viewBox="0 0 6 6"
			aria-hidden="true"
			:class="{
				'fill-blue-400': theme === 'info',
				'fill-green-400': theme === 'success',
				'fill-red-400': theme === 'danger',
				'fill-slate-400': theme === 'neutral' || theme === 'light',
				'fill-yellow-400': theme === 'warning',
				'fill-slate-300': theme === 'dark',
			}"
		>
			<circle cx="3" cy="3" r="3" />
		</svg>

		<span v-if="$slots.default">
			<slot> </slot>
		</span>

		<button
			v-if="removable"
			type="button"
			class="-mr-1 flex h-4 w-4 items-center justify-center rounded-full hover:bg-black/10 active:bg-black/20"
		>
			<span class="sr-only">Fjern</span>

			<Icon name="heroicons:x-mark" class="h-3 w-3" aria-hidden="true" />
		</button>
	</span>
</template>

<script setup lang="ts">
import type { BadgeProps } from '~/types/badge';
const slots = useSlots();

const props = withDefaults(defineProps<BadgeProps>(), {
	theme: 'neutral',
	size: 'default',
});
</script>
