import { formatDistance } from 'date-fns';
import { nb } from 'date-fns/locale';

export default function (date?: Ref<Date>) {
	const now = useNow();

	const ago = computed(() => {
		if (!date) {
			return null;
		}

		return formatDistance(date.value, now.value, {
			locale: nb,
			addSuffix: true,
		});
	});

	return { timeAgo: ago };
}
