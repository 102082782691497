export default function () {
	const query = gql`
		query getMyNotifications {
			myNotifications {
				id
				createdOn
				isRead

				entityId
				instanceId

				resourceId
				data

				responsibleId
				responsibleName
				responsibleImage
			}
		}
	`;

	const { data, refetch: refetchNotifications } = useLegacyQuery(query, {
		key: ['myNotifications'],
		queryOptions: {
			refetchOnWindowFocus: true,
		},
	});

	const notifications = computed(() => {
		if (!data.value) {
			return [];
		}

		return data.value.myNotifications.map((it) => {
			const data = JSON.parse(it.data);

			return {
				...it,
				data,
			};
		});
	});

	const unreadCount = computed(
		() => data.value?.myNotifications?.filter((it) => !it.isRead).length
	);

	const hasUnread = computed(
		() => !!unreadCount.value && unreadCount.value > 0
	);

	// Mark as read
	const mutation = gql`
		mutation markNotificationsAsRead {
			markNotificationsAsRead {
				boolean
				${useErrorFragment()}
			}
		}
	`;

	const { mutateAsync } = useLegacyMutation(mutation);

	function markAsRead() {
		return mutateAsync(null);
	}

	const { invalidate } = useInvalidateQuery(['myNotifications']);

	return {
		unreadCount,
		hasUnread,
		notifications,
		markAsRead,
		invalidate,
	};
}
