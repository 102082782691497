<template>
	<div class="rounded-md bg-red-100 bg-opacity-40 p-4">
		<div class="flex">
			<div class="flex-shrink-0">
				<Icon
					name="heroicons:x-circle-20-solid"
					class="h-5 w-5 text-red-600"
					aria-hidden="true"
				/>
			</div>
			<div class="ml-3">
				<h3
					v-if="slots?.title"
					class="mb-2 text-sm font-semibold text-red-800"
				>
					<slot name="title" />
				</h3>
				<div class="text-sm text-red-800">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { AxosError } from '~/types/axos-api';

const slots = useSlots();
</script>
