import { AxosModules } from '~/types/axos-api';

export default function () {
	const query = gql`
		query getModulesForMe {
			modulesForMe
		}
	`;

	const { data, error, ...args } = useLegacyQuery(query, {
		key: ['modulesForMe'],
		queryOptions: {
			staleTime: 1000 * 60 * 60 * 24,
			cacheTime: 1000 * 60 * 10, // 10 minutes
		},
	});

	useErrorHandler(error);

	function hasModule(modules: AxosModules | AxosModules[]): boolean {
		if (Array.isArray(modules)) {
			return modules.some((module) =>
				data.value?.modulesForMe?.includes(module)
			);
		} else {
			return data.value?.modulesForMe?.includes(modules) ?? false;
		}
	}

	return {
		modules: computed(() => data.value?.modulesForMe),
		hasModule,
		...args,
	};
}
