<template>
	<Teleport to="body">
		<div
			aria-live="assertive"
			class="pointer-events-none fixed inset-0 flex content-end items-end px-4 py-16"
		>
			<div class="flex w-full flex-col items-end space-y-4 sm:items-end">
				<NuxtLink
					v-for="notification in notifications"
					:key="notification.id"
					class="pointer-events-auto flex w-full max-w-md rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
					:to="
						getEntityUrl(
							notification.entityId,
							notification.instanceId
						)
					"
				>
					<div class="w-0 flex-1 p-4">
						<div class="flex items-start">
							<div class="flex-shrink-0 pt-0.5">
								<Image
									v-if="notification?.responsibleImage"
									:src="notification.responsibleImage"
									:height="40"
									:width="40"
									class="h-10 w-10 rounded-full"
								/>
							</div>
							<div class="ml-3 w-0 flex-1">
								<p class="text-sm font-semibold text-gray-900">
									{{ notification.responsibleName }}
								</p>
								<div
									class="text-sm text-gray-600"
									v-dompurify-html="
										$r(
											$s(
												notification.resourceId,
												ResourceContext.FrontpageAndNotification
											),
											notification.data
												? JSON.parse(notification.data)
												: {}
										)
									"
								></div>
							</div>
						</div>
					</div>
					<div class="flex border-l border-gray-200">
						<div
							class="flex w-full items-center justify-center rounded-none rounded-r-lg border border-transparent p-4 text-sm font-medium text-primary hover:text-primary-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
						>
							Se mer
						</div>
					</div>
				</NuxtLink>
			</div>
		</div>
	</Teleport>
</template>
<script setup lang="ts">
import { NotificationDto } from '~/types/axos-api';

const { $r } = useTemplate();
const { getEntityUrl } = useEntityUrl();

const { onEntityEvent } = useNotificationHub();

const notifications = useState<NotificationDto[]>('appNotifications', () => []);

onEntityEvent((event) => {
	showNotification(event);
});

function showNotification(entityEvent: NotificationDto, timeout = 5000) {
	if (notifications.value.length > 2) {
		notifications.value.shift();
	}

	notifications.value.push(entityEvent);
	setTimeout(() => removeNotification(entityEvent.id), timeout);
}

function removeNotification(id: number) {
	notifications.value = notifications.value.filter(
		(notification) => notification.id !== id
	);
}
</script>
