<template>
	<main>
		<!-- Sidebar toggle-->
		<div
			class="fixed top-0 z-[60] flex h-[var(--header-height)] items-center justify-center sm:h-[var(--header-height-sm)] lg:hidden"
			:class="{
				'bg-slate-700': sidebarOpen,
				'bg-primary-600': !sidebarOpen,
				'w-24': showSidebarLabels,
				'w-16': !showSidebarLabels,
			}"
		>
			<button
				type="button"
				class="block p-4 text-slate-200 lg:hidden"
				@click="toggleSidebar"
			>
				<span v-if="!sidebarOpen" class="sr-only">Open sidebar</span>

				<Icon
					v-if="!sidebarOpen"
					name="heroicons:bars-3"
					class="h-7 w-7"
					aria-hidden="true"
				/>

				<span v-if="sidebarOpen" class="sr-only">Close sidebar</span>

				<Icon
					v-if="sidebarOpen"
					name="heroicons:x-mark"
					class="h-7 w-7"
					aria-hidden="true"
				/>
			</button>
		</div>

		<TransitionRoot as="template" :show="sidebarOpen">
			<TransitionChild
				as="template"
				enter="transition-opacity ease-linear duration-150"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="transition-opacity ease-linear duration-150"
				leave-from="opacity-100"
				leave-to="opacity-0"
			>
				<aside
					aria-label="Sidebar"
					class="fixed inset-y-0 z-50 flex shrink-0 lg:mt-[var(--header-height-lg)]"
					:class="{
						'w-96': showSidebarLabels && $slots.sidebar,
						'w-80': $slots.sidebar,
						'w-16': !$slots.sidebar,
					}"
				>
					<!-- Bg overlay-->
					<div
						v-if="sidebarOpen"
						class="fixed inset-0 bg-gray-900/80 lg:hidden"
						@click="sidebarOpen = false"
					></div>

					<!-- Main sidebar with icons -->
					<nav
						class="z-20 mt-[var(--header-height)] shrink-0 bg-slate-700 sm:mt-[var(--header-height-sm)] lg:mt-0"
						:class="{
							'w-24 p-2': showSidebarLabels,
							'w-16 py-1.5': !showSidebarLabels,
						}"
					>
						<ul
							role="list"
							class="flex h-full flex-col gap-y-1"
							:class="{
								'items-left': showSidebarLabels,
								'items-center': !showSidebarLabels,
							}"
						>
							<li v-if="hasModule(AxosModules.Crm)">
								<NuxtLink
									to="/accounts"
									:class="{
										'bg-slate-800 bg-opacity-60 text-white':
											isActive('/account') ||
											isActive('/contacts') ||
											isActive('/crm'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_CRM"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Core.Navigation.CRM') }}
									</span>

									<Tooltip v-else>
										{{ $p('Core.Navigation.CRM') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li
								v-if="
									hasModule([
										AxosModules.Hr,
										AxosModules.Employee,
									])
								"
							>
								<NuxtLink
									to="/employees"
									:class="{
										'bg-slate-800 bg-opacity-60 text-white':
											isActive('/employees') ||
											isActive('/allergies') ||
											isActive('/hr'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_HR"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Core.Navigation.HR') }}
									</span>

									<Tooltip v-else>
										{{ $p('Core.Navigation.HR') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li class="flex-grow"></li>
							<li v-if="hasModule(AxosModules.Organisation)">
								<NuxtLink
									to="/organisations"
									:class="{
										'bg-slate-800 bg-opacity-60 text-white':
											isActive('/organisations'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_ORGANISATION"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $p('Organisation.Entity.Name') }}
									</span>

									<Tooltip v-else>
										{{ $p('Organisation.Entity.Name') }}
									</Tooltip>
								</NuxtLink>
							</li>
							<li v-if="isAdmin">
								<NuxtLink
									to="/admin"
									:class="{
										'bg-slate-800 bg-opacity-60 text-white':
											isActive('/admin'),
									}"
									class="font-regular group relative flex flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="ICON_ADMIN"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<span v-if="showSidebarLabels">
										{{ $s('Core.Navigation.Admin') }}
									</span>

									<Tooltip v-else>
										{{ $s('Core.Navigation.Admin') }}
									</Tooltip>
								</NuxtLink>
							</li>

							<Idea :show-label="showSidebarLabels" />

							<li class="mt-2 border-t border-slate-600 pt-2">
								<button
									@click="toggleSidebarLabels"
									class="font-regular group relative flex w-full flex-col items-center rounded-md p-3 text-xxs leading-6 text-slate-400 transition-all hover:text-white"
								>
									<Icon
										:name="
											showSidebarLabels
												? 'heroicons:chevron-double-left'
												: 'heroicons:chevron-double-right'
										"
										class="h-6 w-6 shrink-0"
										aria-hidden="true"
									/>

									<Tooltip>
										<template v-if="showSidebarLabels">
											{{
												$s(
													'Core.Navigation.CollapseSidebar'
												)
											}}
										</template>
										<template v-else>
											{{
												$s(
													'Core.Navigation.ExpandSidebar'
												)
											}}
										</template>
									</Tooltip>
								</button>
							</li>
						</ul>
					</nav>

					<!-- Sidebar menu -->
					<div
						v-if="$slots.sidebar"
						class="z-10 flex w-72 flex-col overflow-y-auto border-r border-slate-200 bg-white p-4"
					>
						<slot
							class="mx-auto mt-10 flex px-4"
							name="sidebar"
						></slot>
					</div>
				</aside>
			</TransitionChild>
		</TransitionRoot>

		<div
			:class="{
				'lg:pl-80': $slots.sidebar && !showSidebarLabels,
				'lg:pl-96': $slots.sidebar && showSidebarLabels,
				'lg:pl-24': !$slots.sidebar && showSidebarLabels,
				'lg:pl-16': !$slots.sidebar && !showSidebarLabels,
			}"
		>
			<!-- Header -->
			<header
				v-if="$slots.header"
				class="relative z-40 flex min-h-20 items-center border-b border-slate-200 bg-white lg:min-h-28"
			>
				<PageContentWrapper :narrow="narrow">
					<slot name="header"></slot>
				</PageContentWrapper>
			</header>

			<!-- Content -->
			<PageContentWrapper :narrow="narrow">
				<slot> </slot>
			</PageContentWrapper>
		</div>
	</main>
</template>

<script setup lang="ts">
import { TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = defineProps<{
	narrow?: boolean;
}>();

const { isAdmin } = useAccessControl();
const { hasModule } = useModules();

const route = useRoute();
const isActive = (path: string) => {
	return route.path.startsWith(path);
};
const sidebarOpen = ref(window.innerWidth > 1023);
const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const toggleSidebarLabels = () => {
	showSidebarLabels.value = !showSidebarLabels.value;
};

const toggleSidebar = () => {
	sidebarOpen.value = !sidebarOpen.value;
};

useResizeObserver(document.documentElement, (entries) => {
	const { width } = entries[0].contentRect;
	sidebarOpen.value = width > 1023;
});
</script>
