<template>
	<li>
		<Popover class="z-30 flex flex-col items-start" v-slot="{ open }">
			<transition
				enter-active-class="transition duration-150 ease-out"
				enter-from-class="translate-y-2 opacity-0"
				enter-to-class="translate-y-0 opacity-100"
				leave-active-class="transition duration-150 ease-in"
				leave-from-class="translate-y-0 opacity-100"
				leave-to-class="translate-y-2 opacity-0"
			>
				<PopoverPanel
					class="fixed z-10 w-full max-w-[calc(100%-5.5rem)] sm:w-[40rem]"
					:class="{
						'bottom-4 left-20 sm:bottom-32 sm:left-16 ': showLabel,
						'bottom-2 left-20 sm:bottom-28 sm:left-6 ': !showLabel,
					}"
				>
					<Section class="max-h-screen overflow-y-auto shadow-xl">
						<template v-if="showIdeasFromMe">
							<IdeaIdeasFromMe />
							<div class="my-3 flex justify-between">
								<Button
									size="small"
									@click="showIdeasFromMe = false"
								>
									{{ $s('Idea.Button.Back') }}
								</Button>
							</div>
						</template>
						<template v-else>
							<h2 class="mb-4 text-center text-xl font-bold">
								<span v-if="!formSubmitted">
									{{ $s('Idea.Header.IdeaOrFeedback') }}
								</span>
								<span v-else>
									{{ $s('Idea.Header.Thanks') }}
								</span>
							</h2>

							<FormKit
								v-if="!formSubmitted"
								type="form"
								ref="form"
								@submit="submit"
								:actions="false"
							>
								<div class="mb-6 flex flex-col">
									<FormKit
										type="text"
										name="title"
										:label="$s('Idea.Label.Title')"
										validation="required"
									/>

									<FormKit
										type="textarea"
										name="description"
										:label="$s('Idea.Label.Description')"
									/>

									<FormKit
										type="dropdown"
										name="importanceTypeId"
										:label="$s('Idea.Label.Importance')"
										:options-loader="
											getIdeaImportanceTypeOptions
										"
										:option-loader="
											getIdeaImportanceTypeOption
										"
										validation="required"
									/>
									<FormKit
										type="file"
										name="attachments"
										:label="$s('Idea.Label.Attachments')"
										accept="image/*"
										:help="
											$s(
												'Idea.Label.AttachmentsDescription'
											)
										"
									/>
								</div>
								<div class="flex justify-between">
									<Button
										appearance="link"
										size="small"
										class="pl-0"
										@click="showIdeasFromMe = true"
									>
										{{ $s('Idea.Label.MyIdeas') }}
									</Button>
									<div class="sm:flex-end">
										<FormKit type="submit">
											{{ $s('Core.Button.Submit') }}
										</FormKit>
									</div>
								</div>
							</FormKit>

							<div v-else class="text-center">
								<Button
									class="mt-4"
									full-width
									theme="neutral"
									@click="reset"
								>
									{{ $s('Idea.Button.SubmitNewIdea') }}
								</Button>
							</div>
						</template>
					</Section>
				</PopoverPanel>
			</transition>

			<PopoverButton
				class="font-regular group relative flex w-full flex-col items-center gap-x-1.5 rounded-md p-3 text-xxs leading-6 transition-all hover:text-white"
				:class="{
					'bg-slate-800  text-white ': open,
					'text-slate-400 ': !open,
				}"
			>
				<Icon v-if="!open" :name="ICON_IDEA" class="h-6 w-6" />
				<Icon v-else name="heroicons:x-mark" class="h-6 w-6" />

				<span v-if="showLabel">
					{{ $p('Core.Navigation.Ideas') }}
				</span>

				<Tooltip v-else>
					{{ $p('Core.Navigation.Ideas') }}
				</Tooltip>
			</PopoverButton>
		</Popover>
	</li>
</template>
<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { IdeaInput } from '~/types/axos-api';

const props = defineProps<{
	showLabel?: boolean;
}>();

const emit = defineEmits<{
	(e: 'submit'): void;
}>();

const {
	ideaImportanceTypes,
	getIdeaImportanceTypeOption,
	getIdeaImportanceTypeOptions,
} = useDropdownIdeaImportanceType();

const formSubmitted = ref(false);
const showIdeasFromMe = ref(false);

const { invalidate: invalidateIdeas } = useInvalidateQuery(['ideasFromMe']);

const { form, reset: resetForm } = useForm();
const { create, onSuccess } = useIdeaCreate();

onSuccess(() => {
	emit('submit');
	$success($s('Idea.Info.Submitted'));
	formSubmitted.value = true;
});

const { upload } = useImageUpload();

async function submit(data: IdeaInput & { attachments?: any[] }) {
	const file: File = data.attachments?.[0]?.file;
	if (file) {
		const fileRes = await upload(file);
		data.attachmentId = fileRes.uniqueGuid;
	}

	delete data.attachments;

	invalidateIdeas();

	create({ input: data });
}

function reset() {
	resetForm();
	formSubmitted.value = false;
}
</script>
