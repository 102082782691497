import Handlebars from 'handlebars';

export default function () {
	const { getRelativeUrlById } = useEntityUrl();

	// Register helpers
	Handlebars.registerHelper(
		'linkEntityInstance',
		function (title, entityId, instanceId, extraData, options) {
			const entityUrl = getRelativeUrlById(entityId);
			if (!entityUrl) {
				return '#';
			}

			const compiledUrl = renderTemplate(entityUrl, {
				...extraData,
				id: instanceId,
				instanceId,
			});

			const linkClass = 'hover:underline';

			return new Handlebars.SafeString(
				`<a href="${compiledUrl}" class="${linkClass}">${title}</a>`
			);
		}
	);

	Handlebars.registerHelper('bold', function (text) {
		var result = '<b>' + Handlebars.escapeExpression(text) + '</b>';
		return new Handlebars.SafeString(result);
	});

	Handlebars.registerHelper('italic', function (text) {
		var result = '<i>' + Handlebars.escapeExpression(text) + '</i>';
		return new Handlebars.SafeString(result);
	});

	Handlebars.registerHelper('text-primary', function (text, url) {
		var result = `<span class="text-primary">${text}</span>`;
		return new Handlebars.SafeString(result);
	});

	Handlebars.registerHelper('text-hightlight', function (text, url) {
		var result = `<span class="text-primary font-semibold">${text}</span>`;
		return new Handlebars.SafeString(result);
	});

	Handlebars.registerHelper('$s', function (innerResource) {
		return $s(innerResource);
	});

	Handlebars.registerHelper('$sl', function (innerResource) {
		return $s(innerResource).toLowerCase();
	});

	Handlebars.registerHelper('$su', function (innerResource) {
		return $s(innerResource).toUpperCase();
	});

	Handlebars.registerHelper('$p', function (innerResource) {
		return $p(innerResource);
	});

	Handlebars.registerHelper('$pl', function (innerResource) {
		return $p(innerResource).toLowerCase();
	});

	Handlebars.registerHelper('$pu', function (innerResource) {
		return $p(innerResource).toUpperCase();
	});

	function renderTemplate<TData = any>(template: string, data: TData) {
		const compiledTemplate = Handlebars.compile(template);
		return compiledTemplate(data);
	}

	return {
		renderTemplate,
		render: renderTemplate,
		$r: renderTemplate,
	};
}
