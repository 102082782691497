<template>
	<h2 class="mb-4 text-center text-xl font-bold">
		{{ $s('Idea.Header.MyIdeas') }}
	</h2>

	<ErrorMessage v-if="error" :error="error" />

	<PowerTableV3 v-else :table="table" />
</template>

<script setup lang="ts">
import { Idea } from '~/types/axos-api';

const { ideasFromMe, error } = useIdeasFromMe();

const column = usePowerTableColumn<Idea>();
const columnsDefinition = [
	column.textColumn((row) => row.title, {
		id: 'title',
		label: $s('Idea.Label.Title'),
	}),
	column.dateColumn((row) => row.createdOn, {
		id: 'createdOn',
		label: $s('Idea.Label.CreatedOn'),
	}),
	column.badgeColumn((row) => row.statusTypeId, {
		id: 'status',
		label: $s('Idea.Label.Status'),
		items: [
			{
				value: 1,
				label: $s('Master.Domain.IdeaStatusNew'),
				theme: 'info',
			},
			{
				value: 2,
				label: $s('Master.Domain.IdeaStatusPlanned'),
				theme: 'warning',
			},
			{
				value: 3,
				label: $s('Master.Domain.IdeaStatusLaunched'),
				theme: 'success',
			},
			{
				value: 4,
				label: $s('Master.Domain.IdeaStatusConnected'),
				theme: 'info',
			},
			{
				value: 5,
				label: $s('Master.Domain.IdeaStatusRejected'),
				theme: 'danger',
			},
		],
	}),
];

const { table } = usePowerTable({
	columns: columnsDefinition,
	data: ideasFromMe,
	disablePagination: true,
});
</script>
