export default function useDropdownAccount(options?: {
	excludeInternal?: boolean;
	excludeIds?: number[];
}) {
	const { api } = useApiClient();
	const { data, suspense, ...args } = useQuery({
		queryKey: ['accountDropdown', options],
		queryFn: () =>
			api.getAccountDropdown(
				options?.excludeInternal,
				options?.excludeIds?.join(',')
			),
		select: (data) => data.items,
		staleTime: 1000 * 60 * 60, // 1 hour
	});

	const { mappedData, getOption, getOptions, getOptionLabel } =
		useDropdownUtils(data, suspense);

	return {
		accountsRaw: data,
		accounts: mappedData,
		getAccountOption: getOption,
		getAccountOptions: getOptions,
		getAccountOptionLabel: getOptionLabel,
		...args,
	};
}
