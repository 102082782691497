<template>
	<Popover ref="popover" class="relative inline-block text-left">
		<slot name="button"></slot>

		<transition
			enter-active-class="transition ease-out duration-150"
			enter-from-class="transform opacity-0 scale-95"
			enter-to-class="transform opacity-100 scale-100"
			leave-active-class="transition ease-in duration-75"
			leave-from-class="transform opacity-100 scale-100"
			leave-to-class="transform opacity-0 scale-95"
		>
			<PopoverPanel
				:static="true"
				class="ax-popover absolute left-0 z-20 mt-1 w-72 min-w-[14rem] origin-top-left focus:outline-none lg:w-max lg:max-w-sm"
				:class="{
					'!left-auto !right-0 !origin-top-right': origin === 'right',
				}"
				v-if="modelValue"
			>
				<slot></slot>
			</PopoverPanel>
		</transition>
	</Popover>
</template>
<script setup lang="ts">
import { Popover, PopoverPanel } from '@headlessui/vue';
import { onClickOutside } from '@vueuse/core';

const props = withDefaults(
	defineProps<{
		modelValue: boolean;
		origin: 'left' | 'right';
	}>(),
	{
		origin: 'left',
	}
);

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void;
}>();

const popover = ref(null);

onClickOutside(popover, (event) => {
	if (props.modelValue) {
		closePopover();
	}
});

function openPopover() {
	emit('update:modelValue', true);
}

function closePopover() {
	emit('update:modelValue', false);
}

defineExpose({
	openPopover,
	closePopover,
});
</script>
