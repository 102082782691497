<template>
	<NuxtLink
		v-if="account"
		:to="getAccountUrl(accountId)"
		@click="onClick"
		class="group flex items-center"
	>
		<div
			v-if="account?.symbol"
			class="relative h-7 w-7 rounded-full border bg-white"
		>
			<Image
				:src="account.symbol"
				:height="56"
				:width="56"
				class="relative aspect-square h-full w-full rounded-full object-cover p-px"
			/>
		</div>
		<div
			v-else
			:class="[
				'relative flex h-7 w-7 items-center justify-center rounded-full',
				initials.length > 2 ? 'text-xs' : 'text-sm',
				'font-semibold',
				textColorClass,
			]"
		>
			{{ initials }}
		</div>

		<span
			class="-ml-6 flex items-center self-stretch truncate rounded-full bg-primary-50 pl-8 pr-3 text-xs text-slate-600 group-hover:underline"
		>
			{{ account.label }}
		</span>
	</NuxtLink>
	<template v-else-if="(!account && !isPending) || isError">
		<NuxtLink
			:to="getAccountUrl(accountId)"
			@click="onClick"
			class="group flex items-center"
		>
			<span
				class="flex items-center self-stretch truncate rounded-full bg-primary-50 px-3 text-sm text-slate-600 group-hover:underline"
			>
				{{ $s('Account.Entity.Name') }} #{{ accountId }}
			</span>
		</NuxtLink>
	</template>
</template>

<script setup lang="ts">
const props = defineProps<{
	accountId: number;
}>();

const emit = defineEmits<{
	(e: 'click'): void;
}>();

const { getAccountUrl } = useEntityUrl();
const { accountsRaw, isPending, isError } = useDropdownAccount();

const account = computed(() =>
	accountsRaw.value?.find((a) => a.value === props.accountId)
);

const initials = computed(() => {
	if (!account.value?.label) return '';
	return $toInitials(account.value.label, { maxLength: 2 });
});
const textColorClass = computed(() => {
	if (!initials.value) return '';
	return $textToColor(initials.value);
});

const onClick = () => {
	emit('click');
};
</script>
