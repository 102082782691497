import { AxosModules, Query } from '~/types/axos-api';

export default defineNuxtRouteMiddleware(async (to, from) => {
	const { IS_DEV } = useEnvironment();
	if (IS_DEV && to.path === '/setup/client') {
		return;
	}

	const app = useNuxtApp();

	// Cache module access state in-memory
	const moduleAccessState = useState<AxosModules[] | null>('moduleAccess');

	if (to.meta?.moduleAccess) {
		const query = gql`
			query getModulesForMe {
				modulesForMe
			}
		`;

		try {
			let moduleAccesses: AxosModules[];

			if (
				!moduleAccessState.value ||
				moduleAccessState.value.length === 0
			) {
				const res = await app.$graphql.request<Query>(query);
				const availableModules = res.modulesForMe;
				moduleAccesses = availableModules;
				moduleAccessState.value = availableModules;
			} else {
				moduleAccesses = moduleAccessState.value;
			}

			let hasModuleAccess = false;

			// If modules is array, check that at least one of the module Ids from array is present in availableModules
			if (Array.isArray(to.meta.moduleAccess)) {
				hasModuleAccess = to.meta.moduleAccess.some((module) =>
					moduleAccesses.some((am) => am === module)
				);
			} else {
				hasModuleAccess = moduleAccesses.some(
					(am) => am === to.meta.moduleAccess
				);
			}

			if (!hasModuleAccess) {
				return navigateTo({
					name: 'order-module',
					query: {
						redirect: to.fullPath,
					},
				});
			}
		} catch (error) {
			console.error(
				'Failed to fetch modules for user in middleware',
				error
			);
		}
	}
});
