import {
	dehydrate,
	DehydratedState,
	hydrate,
	QueryClient,
	VueQueryPlugin,
	VueQueryPluginOptions,
} from '@tanstack/vue-query';

export default defineNuxtPlugin((nuxt) => {
	const vueQueryState = useState<DehydratedState | null>('vue-query');

	const queryClient = new QueryClient({
		defaultOptions: {
			// TODO: For testing purposes, remove this
			queries: {
				// staleTime: Infinity,
				refetchOnWindowFocus: false,
				retry: false,
			},
			mutations: {
				retry: false,
			},
		},
	});

	const options: VueQueryPluginOptions = {
		queryClient,
	};

	nuxt.vueApp.use(VueQueryPlugin, options);

	if (process.server) {
		nuxt.hooks.hook('app:rendered', () => {
			vueQueryState.value = dehydrate(queryClient);
		});
	}

	if (process.client) {
		nuxt.hooks.hook('app:created', () => {
			hydrate(queryClient, vueQueryState.value);
		});
	}
});
