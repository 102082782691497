<template>
	<NuxtLink
		v-if="employee"
		:to="getEmployeeUrl(employeeId)"
		@click="onClick"
		class="group flex items-center"
	>
		<Image
			v-if="employee?.profilePicture"
			:src="employee.profilePicture"
			:height="56"
			:width="56"
			class="relative aspect-square h-7 w-7 rounded-full object-cover"
		/>
		<div
			v-else
			:class="[
				'relative flex h-7 w-7 items-center justify-center rounded-full',
				initials.length > 2 ? 'text-xs' : 'text-sm',
				'font-semibold',
				textColorClass,
			]"
		>
			{{ initials }}
		</div>

		<span
			class="-ml-6 flex items-center self-stretch truncate rounded-full bg-primary-50 pl-8 pr-3 text-xs font-medium text-slate-600 group-hover:underline"
		>
			{{ employee.label }}
		</span>
	</NuxtLink>
	<template v-else-if="(!employee && !isPending) || isError">
		<NuxtLink
			:to="getEmployeeUrl(employeeId)"
			@click="onClick"
			class="group flex items-center"
		>
			<span
				class="flex items-center self-stretch truncate rounded-full bg-primary-50 px-3 text-xs font-medium text-slate-600 group-hover:underline"
			>
				{{ $s('Employee.Entity.Name') }} #{{ employeeId }}
			</span>
		</NuxtLink>
	</template>
</template>

<script setup lang="ts">
const props = defineProps<{
	employeeId: number;
}>();

const { getEmployeeUrl } = useEntityUrl();
const { employeesRaw, isPending, isError } = useDropdownEmployee();

const employee = computed(() =>
	employeesRaw.value?.find((it) => it.value === props.employeeId)
);

const emit = defineEmits<{
	(e: 'click'): void;
}>();

const initials = computed(() => {
	if (!employee.value) return '';
	if (employee.value.initials) return employee.value.initials;
	return $toInitials(employee.value.label, { maxLength: 2 });
});

const textColorClass = computed(() => {
	if (!initials.value) return '';
	return $textToColor(initials.value);
});

const onClick = () => {
	emit('click');
};
</script>
