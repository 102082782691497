import {
	ColumnOrderState,
	functionalUpdate,
	makeStateUpdater,
	OnChangeFn,
	PaginationState,
	RowData,
	SortingState,
	Table,
	TableFeature,
	Updater,
	VisibilityState,
} from '@tanstack/vue-table';
import { RouteLocationRaw } from 'vue-router';
import { DateFormat, TimeFormat } from '~/types/axos-api';

/**
 * Final state of a PowerTable, used for saving and restoring state
 */
export interface PowerTableStateV3<TData extends RowData> {
	columnVisibility: VisibilityState;
	columnOrder: ColumnOrderState;
	sorting: SortingState;
	pagination: PaginationState;
	columnProperties: ColumnPropertiesState;
	tableProperties: TablePropertiesState<TData>;
	tableFilterVisibility: TableFilterVisibilityState;
	tableFilterValue: TableFilterValueState;
}

// CUSTOM FEATURE: COLUMN PROPERTIES
export interface ColumnProperties {
	// GENERAL
	align?: 'left' | 'center' | 'right';

	// STRING
	isNoWrap?: boolean;

	// NUMBER
	showSum?: boolean;
	showAverage?: boolean;
	showMedian?: boolean;

	// DATE
	dateFormat?: DateFormat | null;
	timeFormat?: TimeFormat | null;
}
export interface ColumnPropertiesState {
	[key: string]: {
		powerTableProperties: ColumnProperties;
	};
}

// CUSOTM FEATURE: TABLE PROPERTIES
export interface TablePropertiesState<TData extends RowData> {
	name: string;
	zebra?: boolean;
}

// CUSOTM FEATURE: TABLE FILTERS
export interface FilterObject {
	[key: string]: any;
}

export interface TableFilter<TFilters extends FilterObject = FilterObject> {
	id: keyof TFilters;
	type:
		| 'text'
		| 'number'
		| 'date'
		| 'boolean'
		| 'select'
		| 'employee'
		| string;
	label: string;
	icon?: string;
	meta?: any;
}
export type TableFiltersState<TFilters extends FilterObject = FilterObject> =
	TableFilter<TFilters>[];

export type TableFilterVisibilityState = Record<string, boolean>;
export type TableFilterValueState = Record<string, any>;

// CUSTOM FEATURE: ROW LINK
export type PowerTableRowLink<TData extends RowData> = (
	row: TData
) => RouteLocationRaw | undefined;

// TABLE AND COLUMN STATES WITH CUSTOM FEATURES
export interface PowerTableTableState {
	columnProperties: ColumnPropertiesState;
	tableProperties: TablePropertiesState<any>;
	tableFilters: TableFiltersState;
	tableFilterVisibility: TableFilterVisibilityState;
	tableFilterValue: TableFilterValueState;
	rowLink: PowerTableRowLink<any>;
}
export interface PowerTableColumn {
	setProperties(properties: Partial<ColumnProperties>): void;
	getProperties(): ColumnProperties;
	moveLeft(): void;
	moveRight(): void;
	remove(): void;
	add(): void;
}

export interface PowerTableOptions<TData extends RowData> {
	onColumnPropertiesChange?: OnChangeFn<ColumnPropertiesState>;
	onTablePropertiesChange?: OnChangeFn<TablePropertiesState<TData>>;
	onTableFiltersChange?: OnChangeFn<TableFiltersState>;
	onTableFilterVisibilityChange?: OnChangeFn<TableFilterVisibilityState>;
	onTableFilterValueChange?: OnChangeFn<TableFilterValueState>;
	rowLink?: PowerTableRowLink<TData>;
}

export interface PowerTableInstance<TData extends RowData> {
	setColumnProperties: (state: Updater<ColumnPropertiesState>) => void;
	setTableProperties: (state: Updater<TablePropertiesState<TData>>) => void;
	getTableProperties: () => TablePropertiesState<TData>;

	setTableFilters: (state: Updater<TableFiltersState>) => void;
	getTableFilters: () => TableFiltersState;
	getTableFiltersObject: () => any;
	addTableFilter: (filterId: string) => void;
	removeTableFilter: (filterId: string) => void;

	setTableFilterVisibility: (
		state: Updater<TableFilterVisibilityState>
	) => void;
	getTableFilterVisibility: () => TableFilterVisibilityState;

	setTableFilterValues: (updater: Updater<TableFilterValueState>) => void;
	setTableFilterValue: (filterId: string, value: any) => void;
	getTableFilterValue: (filterId: string) => any;
	getTableFilterValues: () => TableFilterValueState;

	getPowerTableState: () => Partial<PowerTableStateV3<TData>>;
	setPowerTableState: (state: PowerTableStateV3<TData>) => void;
	addColumn: (columnId: string) => void;

	getRowLink: (row: TData) => RouteLocationRaw | undefined;
}

export default function <TData>() {
	const tableFeature: TableFeature<TData> = {
		createTable: (table) => {
			table.setColumnProperties = (
				updater: Updater<ColumnPropertiesState>
			) => {
				const safeUpdater: Updater<ColumnPropertiesState> = (old) => {
					let newState = functionalUpdate(updater, old);
					return newState;
				};
				return table.options.onColumnPropertiesChange?.(safeUpdater);
			};

			table.setTableProperties = (
				updater: Updater<TablePropertiesState<TData>>
			) => {
				const safeUpdater: Updater<TablePropertiesState<TData>> = (
					old
				) => {
					let newState = functionalUpdate(updater, old);
					return newState;
				};
				return table.options.onTablePropertiesChange?.(safeUpdater);
			};
			table.getTableProperties = () => {
				return table.getState().tableProperties;
			};

			table.setTableFilters = (updater: Updater<TableFiltersState>) => {
				const safeUpdater: Updater<TableFiltersState> = (old) => {
					let newState = functionalUpdate(updater, old);
					return newState;
				};

				return table.options.onTableFiltersChange?.(safeUpdater);
			};

			table.addTableFilter = (filterId: string) => {
				table.setTableFilterVisibility((prev) => ({
					...prev,
					[filterId]: true,
				}));
			};

			table.removeTableFilter = (filterId: string) => {
				table.setTableFilterValues((prev) => ({
					...prev,
					[filterId]: undefined,
				}));

				table.setTableFilterVisibility((prev) => {
					const newFilterVisibility = prev;
					delete newFilterVisibility[filterId];
					return newFilterVisibility;
				});
			};

			table.setTableFilterVisibility = (
				updater: Updater<TableFilterVisibilityState>
			) => {
				const safeUpdater: Updater<TableFilterVisibilityState> = (
					old
				) => {
					let newState = functionalUpdate(updater, old);
					return newState;
				};

				return table.options.onTableFilterVisibilityChange?.(
					safeUpdater
				);
			};

			table.getTableFilterVisibility = () => {
				return table.getState().tableFilterVisibility;
			};

			table.setTableFilterValues = (
				updater: Updater<TableFilterVisibilityState>
			) => {
				const safeUpdater: Updater<TableFilterValueState> = (old) => {
					let newState = functionalUpdate(updater, old);
					return newState;
				};

				return table.options.onTableFilterValueChange?.(safeUpdater);
			};

			table.setTableFilterValue = (filterId: string, value: any) => {
				table.setTableFilterValues((prev) => ({
					...prev,
					[filterId]: value,
				}));
			};

			table.getTableFilterValue = (filterId: string) => {
				return table.getState().tableFilterValue?.[filterId];
			};

			table.getTableFilterValues = () => {
				return table.getState().tableFilterValue;
			};

			table.getTableFilters = () => {
				return table.getState().tableFilters;
			};

			table.getRowLink = (row: TData) => {
				return table.options.rowLink?.(row) ?? undefined;
			};

			table.getPowerTableState = () => {
				return {
					columnVisibility: table.getState().columnVisibility,
					columnOrder: table.getState().columnOrder,
					sorting: table.getState().sorting,
					pagination: table.getState().pagination,
					columnProperties: table.getState().columnProperties,
					tableProperties: table.getState().tableProperties,
					tableFilterVisibility:
						table.getState().tableFilterVisibility,
					tableFilterValue: table.getState().tableFilterValue,
				};
			};

			table.setPowerTableState = (state: PowerTableStateV3<TData>) => {
				table.setColumnVisibility(state.columnVisibility);
				table.setColumnOrder(state.columnOrder);
				table.setSorting(state.sorting);
				table.setPagination(state.pagination);
				table.setTableProperties(state.tableProperties);
				table.setColumnProperties(state.columnProperties);
				table.setTableFilterVisibility(state.tableFilterVisibility);
				table.setTableFilterValues(state.tableFilterValue);
			};

			table.addColumn = (columnId: string) => {
				const col = table.getColumn(columnId);
				if (!col) return;

				// Check that column is not already visible
				if (col.getIsVisible()) return;

				// First move column to the end
				const existingOrder = table
					.getAllLeafColumns()
					.map((c) => c.id);

				const newOrder = [...existingOrder];
				newOrder.splice(newOrder.indexOf(columnId), 1);
				newOrder.push(columnId);

				table.setColumnOrder(newOrder);

				col.toggleVisibility(true);
			};

			table.getTableFiltersObject = () => {
				return table.getState().tableFilters?.reduce((acc, filter) => {
					acc[filter.id as keyof typeof acc] = filter.value;
					return acc;
				}, {} as any);
			};
		},

		createColumn: (column, table) => {
			column.setProperties = (properties: Partial<ColumnProperties>) => {
				table.setColumnProperties((prev) => ({
					...prev,
					[column.id]: {
						powerTableProperties: {
							...prev[column.id]?.powerTableProperties,
							...properties,
						},
					},
				}));
			};

			column.getProperties = () => {
				const columnProperties =
					table.getState().columnProperties?.[column.id]
						?.powerTableProperties;

				return columnProperties ?? {};
			};

			column.moveLeft = () => {
				const columnsOrder = table.getState().columnOrder;
				const visibleColumns = columnsOrder.filter(
					(colId) =>
						table.getColumn(colId)?.getIsVisible() &&
						!table.getColumn(colId)?.getIsPinned()
				);
				const newOrder = [...columnsOrder];

				const index = visibleColumns.indexOf(column.id);

				if (index > 0) {
					const swapColumnId = visibleColumns[index - 1];
					const currentColumnIndex = newOrder.indexOf(column.id);
					const swapColumnIndex = newOrder.indexOf(swapColumnId);

					newOrder[currentColumnIndex] = swapColumnId;
					newOrder[swapColumnIndex] = column.id;

					table.setColumnOrder(newOrder);
				}
			};

			column.moveRight = () => {
				const columnsOrder = table.getState().columnOrder;
				const visibleColumns = columnsOrder.filter(
					(colId) =>
						table.getColumn(colId)?.getIsVisible() &&
						!table.getColumn(colId)?.getIsPinned()
				);
				const newOrder = [...columnsOrder];

				const index = visibleColumns.indexOf(column.id);

				if (index < visibleColumns.length - 1) {
					const swapColumnId = visibleColumns[index + 1];
					const currentColumnIndex = newOrder.indexOf(column.id);
					const swapColumnIndex = newOrder.indexOf(swapColumnId);

					newOrder[currentColumnIndex] = swapColumnId;
					newOrder[swapColumnIndex] = column.id;

					table.setColumnOrder(newOrder);
				}
			};

			column.remove = () => {
				column.toggleVisibility(false);

				// Remove column from the order
				const updatedOrder = table
					.getState()
					.columnOrder.filter((it) => it !== column.id);

				table.setColumnOrder(updatedOrder);
			};

			column.add = () => {
				column.toggleVisibility(true);

				// Add column to the end
				const existingOrder = table.getState().columnOrder;
				const newOrder = [...existingOrder, column.id];

				table.setColumnOrder(newOrder);
			};
		},

		getDefaultOptions: <TData extends RowData>(
			table: Table<TData>
		): PowerTableOptions<TData> => {
			return {
				onColumnPropertiesChange: makeStateUpdater(
					'columnProperties',
					table
				),
				onTablePropertiesChange: makeStateUpdater(
					'tableProperties',
					table
				),
				onTableFiltersChange: makeStateUpdater('tableFilters', table),
				onTableFilterVisibilityChange: makeStateUpdater(
					'tableFilterVisibility',
					table
				),
				onTableFilterValueChange: makeStateUpdater(
					'tableFilterValue',
					table
				),
			};
		},
	};

	return {
		tableFeature,
	};
}
