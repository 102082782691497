<template>
	<PopoverV2 v-model="showModel" origin="right">
		<template #button>
			<Button
				:aria-label="$s('EntityGrid.Button.AddColumn')"
				icon="heroicons:plus-circle-20-solid"
				appearance="link"
				size="large"
				theme="success"
			></Button>
		</template>

		<div class="p-4">
			<h3 class="mb-2 text-sm font-semibold text-slate-700">
				{{ $s('EntityGrid.Header.AddColumns') }}
			</h3>

			<hr />

			<div class="mt-2 flex flex-col space-y-1 py-1">
				<template v-for="column of columnsWithVisibilityEnabled">
					<div class="relative flex items-start">
						<div class="flex h-6 items-center">
							<input
								:id="'add-dropdown-' + column.id"
								:name="'add-dropdown-' + column.id"
								type="checkbox"
								:checked="column.getIsVisible()"
								@change="toggleColumn(column)"
								class="ax-form-checkbox h-4 w-4"
							/>
						</div>
						<div class="ml-3 text-sm leading-6">
							<label
								:for="'add-dropdown-' + column.id"
								class="inline-block w-full font-medium text-slate-700"
							>
								<FlexRender :render="column.columnDef.header" />
							</label>
						</div>
					</div>
				</template>
			</div>
		</div>
	</PopoverV2>
</template>

<script setup lang="ts" generic="TData">
import { Column, FlexRender } from '@tanstack/vue-table';

const props = defineProps<{ table: PowerTable<TData> }>();

const showModel = defineModel<boolean>('show', {
	default: false,
});

const emit = defineEmits<{
	(e: 'open'): void;
	(e: 'close'): void;
}>();

const columnsWithVisibilityEnabled = computed(() =>
	props.table
		.getAllColumns()
		.filter((it) => it.columnDef.enableHiding !== false)
		.sort(
			(a, b) =>
				$s(a.columnDef.header?.toString()).localeCompare(
					$s(b.columnDef.header?.toString()) ?? ''
				) ?? 0
		)
);

function toggleColumn(column: Column<TData>) {
	if (column.getIsVisible()) {
		column.remove();
	} else {
		column.add();
	}
}
</script>
