import { useQuery } from '@tanstack/vue-query';

export default function useDropdownEmployee() {
	const query = gql`
		query getCustomEmployeeDropdown {
			employeeDropdown {
				value
				label
				profilePicture
				initials
				isDeleted
			}
		}
	`;

	const { data, suspense, ...args } = useQuery({
		queryKey: ['employeeDropdown'],
		queryFn: useGQLRequest(query),
		select: (data) => data.employeeDropdown,
		// Staletime is set to 1 hour
		staleTime: 1000 * 60 * 60,
	});

	const { mappedData, getOption, getOptions, getOptionLabel } =
		useDropdownUtils(data, suspense);

	return {
		employees: mappedData,
		employeesRaw: data,
		getEmployeeOption: getOption,
		getEmployeeOptions: getOptions,
		getEmployeeOptionLabel: getOptionLabel,
		suspense,
		...args,
	};
}
