<template>
	<div class="group relative z-10 h-20 w-20">
		<div
			v-if="isPending"
			class="absolute bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center"
		>
			<LoadingSpinner />
		</div>

		<template v-if="context?._value">
			<img
				:src="context?._value"
				@click="triggerFileSelect"
				class="h-full w-full rounded-full object-cover transition-all"
				:class="{
					'opacity-80': isPending,
					'opacity-100': !isPending,
				}"
			/>
		</template>

		<span
			class="absolute inset-0 flex cursor-pointer items-center justify-center rounded-full bg-slate-900 bg-opacity-50 text-white opacity-0 transition-opacity group-hover:opacity-100"
			@click="triggerFileSelect"
		>
			<Icon
				name="heroicons:cloud-arrow-up"
				class="h-9 w-9"
				aria-hidden="true"
			/>
		</span>
	</div>

	<input
		:id="context?.id"
		type="file"
		class="hidden"
		accept="image/*"
		@change="onFileSelected"
	/>
</template>

<script setup lang="ts">
const props = defineProps({
	context: Object,
});

const emit = defineEmits(['update:modelValue']);

const file = ref<File | null>(null);

function handleInput(value: string) {
	emit('update:modelValue', value);
	props.context?.node.input(value);
}

function triggerFileSelect() {
	const input = document.getElementById(
		props.context!.id
	) as HTMLInputElement;
	input.click();
}

const { upload, isPending } = useImageUpload();
async function onFileSelected(event: Event) {
	const target = event.target as HTMLInputElement;
	const fileList = target.files;

	const selectedFile = fileList?.[0];
	if (!selectedFile) {
		return;
	}

	file.value = selectedFile;

	const uploadedFile = await upload(file.value);
	handleInput(uploadedFile.fileUrl!);
}
</script>
