<template>
	<Popover origin="right" v-model="showInbox">
		<template #button>
			<button
				class="group mr-3 cursor-pointer border-r border-white/25 p-1 pr-5"
				@click="showInbox = !showInbox"
			>
				<span class="flex items-center">
					<Icon
						name="heroicons:bell-20-solid"
						class="h-7 w-7 text-white transition-transform group-hover:scale-110 group-hover:transform"
						aria-hidden="true"
					/>

					<span
						v-if="hasUnread"
						class="absolute left-5 top-0 block h-5 w-5 rounded-full bg-red-400 text-center text-xs font-semibold leading-5 text-white"
					>
						{{ unreadCount }}
					</span>
				</span>
			</button>
		</template>

		<div
			v-if="notifications.length > 0"
			class="flow-root max-h-[60svh] overflow-y-auto"
		>
			<ul role="list">
				<NotificationItem
					v-for="(notification, notificationIdx) in notifications"
					:is-last="notificationIdx === notifications.length - 1"
					:notification="notification"
					:key="notification.id"
				/>
			</ul>
		</div>

		<div v-else class="p-4 text-center text-sm text-slate-400">
			{{ $s('Core.Info.NoNotifications') }}
		</div>
	</Popover>
</template>

<script setup lang="ts">
const showInbox = ref(false);

const { notifications, hasUnread, unreadCount, markAsRead, invalidate } =
	useNotifications();

watch(
	() => showInbox.value,
	async () => {
		if (showInbox.value) {
			await markAsRead();
			await invalidate();
		}
	}
);
</script>
