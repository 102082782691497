<template>
	<header
		class="fixed inset-x-0 top-0 z-50 overflow-y-visible bg-primary"
		:class="{
			'bg-slate-800': profile?.isImpersonated,
		}"
	>
		<div class="mx-auto h-14">
			<!-- Mobile menu toggle -->
			<div class="relative flex h-full">
				<!-- Logo -->
				<NuxtLink
					to="/"
					class="flex w-16 flex-shrink-0 items-center justify-center transition-colors hover:bg-[rgba(255,255,255,.12)] lg:mx-auto lg:ml-0 lg:bg-[rgba(255,255,255,.07)]"
					:class="{
						'ml-24 lg:w-24': showSidebarLabels,
						'ml-16': !showSidebarLabels,
					}"
				>
					<svg
						id="Layer_2"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 116 102"
						class="h-7"
					>
						<g id="Layer_1-2" fill="#ffffff">
							<path
								class="cls-1"
								d="M32.68,31.69L1.25,87.41c-.82,1.45-1.25,3.08-1.25,4.75,0,5.34,4.32,9.66,9.66,9.66h22.47c4.65,0,8.93-2.5,11.22-6.55l12.15-21.54-21.02-37.26c-.85-1.51-1.43-3.13-1.8-4.78Z"
							/>
							<path
								class="cls-1"
								d="M114.98,87.41L69.36,6.55c-1.53-2.72-3.92-4.64-6.62-5.67-.05-.02-.1-.04-.16-.06-.56-.21-1.14-.39-1.72-.52-.04,0-.08-.02-.11-.03-.32-.07-.65-.12-.97-.16-.12-.01-.23-.03-.35-.04-.25-.03-.5-.04-.76-.05-.18,0-.37-.02-.55-.02-.19,0-.37,.01-.55,.02-.25,.01-.5,.02-.76,.05-.12,.01-.23,.03-.35,.04-.33,.04-.65,.09-.97,.16-.04,0-.08,.02-.11,.03-1.21,.27-2.41,.72-3.55,1.36-2.05,1.15-3.74,2.84-4.89,4.89l-8.48,15.02c-2.22,3.93-2.22,8.73,0,12.66l19.66,34.85,14.77,26.18c2.28,4.05,6.57,6.55,11.22,6.55h22.47c5.34,0,9.66-4.32,9.66-9.66,0-1.66-.43-3.3-1.25-4.75Z"
							/>
						</g>
					</svg>
				</NuxtLink>

				<span
					class="pointer-events-none absolute bottom-5 rounded-full bg-green-400 px-1 text-[8px] font-semibold uppercase leading-4 text-white shadow"
					:class="{
						'left-[98px] sm:left-[112px] lg:left-12':
							showSidebarLabels,
						'left-[74px] sm:left-[85px] lg:left-8':
							!showSidebarLabels,
					}"
					>Beta</span
				>

				<!-- Search -->
				<AppSearch />

				<!-- Actions -->
				<div class="ml-auto flex items-center justify-end gap-x-3 px-4">
					<!-- Notification inbox -->
					<NotificationInbox />

					<!-- Organisation dropdown -->
					<OrganisationSwitch />

					<!-- Profile dropdown -->
					<Menu as="div" class="relative flex-shrink-0">
						<div>
							<MenuButton
								class="group flex overflow-hidden rounded-full bg-slate-800"
							>
								<span class="sr-only">Open user menu</span>

								<Image
									v-if="profile?.profilePicture"
									:src="profile.profilePicture"
									:height="72"
									:width="72"
									class="aspect-square h-9 w-9 rounded-full object-cover transition-opacity group-hover:opacity-90"
									:alt="`${profile?.firstName} ${profile?.lastName} `"
								/>
								<div
									v-else
									class="flex h-9 w-9 items-center justify-center rounded-full bg-white font-bold text-primary"
								>
									{{ profile?.initials }}
								</div>
							</MenuButton>
						</div>
						<transition
							enter-active-class="transition ease-out duration-100"
							enter-from-class="transform opacity-0 scale-95"
							enter-to-class="transform opacity-100 scale-100"
							leave-active-class="transition ease-in duration-75"
							leave-from-class="transform opacity-100 scale-100"
							leave-to-class="transform opacity-0 scale-95"
						>
							<MenuItems
								class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
							>
								<MenuItem v-slot="{ active, close }">
									<NuxtLink
										:to="`/employees/${profile?.id}`"
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full px-4 py-2 text-left text-sm font-semibold text-slate-700',
										]"
										@click="close"
									>
										{{ profile?.firstName }}
										{{ profile?.lastName }}
									</NuxtLink>
								</MenuItem>

								<MenuItem
									v-if="profile?.isImpersonated"
									v-slot="{ active }"
								>
									<button
										to="/auth/client"
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full border-t border-slate-200 px-4 py-2 text-left text-sm text-slate-700',
										]"
										@click="stopImpersonating"
									>
										Bytt tilbake til
										<p class="font-semibold">
											{{
												profile.impersonatedBy
													?.firstName
											}}
											{{
												profile.impersonatedBy?.lastName
											}}
										</p>
									</button>
								</MenuItem>
								<MenuItem
									v-if="currentClient"
									v-slot="{ active }"
								>
									<NuxtLink
										to="/auth/client"
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full border-t border-slate-200 px-4 py-2 text-left text-xs font-medium text-slate-500',
										]"
									>
										{{ currentClient.name }} (bytt)
									</NuxtLink>
								</MenuItem>

								<MenuItem v-slot="{ active }">
									<button
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full border-t border-slate-200 px-4 py-2 text-left text-sm text-slate-700',
										]"
										@click="logout"
									>
										Logg ut
									</button>
								</MenuItem>
								<MenuItem v-if="isAdmin" v-slot="{ active }">
									<button
										:class="[
											active ? 'bg-slate-100' : '',
											'block w-full px-4 py-2 text-left  text-sm text-red-600',
										]"
										@click="clearAccess"
									>
										Tøm tilgang cache
									</button>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
		</div>
	</header>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';

const { isAdmin } = useAccessControl();

const { profile } = useProfile();
const { logout } = useAuthAxos();

const { stopImpersonating } = useImpersonate();

const { currentClient } = useAuthClient();

const showSidebarLabels = useLocalStorage('showSidebarLabels', true);

const { clearAccessCache } = useClearAccessCache();
async function clearAccess() {
	await clearAccessCache();
	$success('Tilgang cache tømt');
}
</script>
