interface AcceptedDropdownItem {
	value: number;
	label: string;
	sortIndex?: number;
	isDeleted?: boolean;
}

export default function <T extends AcceptedDropdownItem>(
	data: Ref<undefined | T[]>,
	suspense: () => Promise<any>
) {
	const mappedData = computed(() => getLocalizedSortedData(data.value));

	function getLocalizedSortedData(data?: AcceptedDropdownItem[]) {
		const mappedData =
			data?.map((item: AcceptedDropdownItem) => ({
				...item,
				label: $s(item.label),
				attrs: { deleted: item.isDeleted, disabled: item.isDeleted },
			})) ?? [];

		const hasPositiveSortIndex = mappedData?.some(
			(item: AcceptedDropdownItem) => item.sortIndex && item.sortIndex > 0
		);

		if (hasPositiveSortIndex) {
			return mappedData?.sort(
				(a: AcceptedDropdownItem, b: AcceptedDropdownItem) => {
					const indexA = a.sortIndex || 0; // Treat null or undefined as 0
					const indexB = b.sortIndex || 0;
					return indexA - indexB;
				}
			);
		} else {
			return mappedData?.sort(
				(a: AcceptedDropdownItem, b: AcceptedDropdownItem) =>
					a.label.localeCompare(b.label)
			);
		}
	}

	async function getOptions(options?: { search?: string }) {
		await suspense();

		if (options?.search) {
			return mappedData.value?.filter((item) =>
				item.label.toLowerCase().includes(options.search!.toLowerCase())
			);
		}

		return mappedData.value;
	}

	async function getOption<T extends AcceptedDropdownItem>(
		value: number | string | null,
		cachedItem?: T
	) {
		if (cachedItem) {
			return cachedItem;
		}

		await suspense();

		const item = mappedData.value?.find(
			(item) => item.value === value || item.__original === value
		);

		return item ?? null;
	}

	function getOptionLabel(value: number | string | null): string | null {
		if (!value) {
			return null;
		}

		const item = mappedData.value?.find(
			(item) => item.value === value || item.__original === value
		);

		return item?.label ?? null;
	}

	return {
		getLocalizedSortedData,
		getOptions,
		getOption,
		getOptionLabel,
		mappedData,
	};
}
