import { NotificationDto } from '~/types/axos-api';

export default function () {
	const app = useNuxtApp();
	const connection = app.$signalR;

	const { invalidate } = useInvalidateQuery();

	const onEntityEvent = createEventHook<NotificationDto>();

	connection.on('ReceiveNotification', (notification: NotificationDto) => {
		const formattedData: NotificationDto = {
			...notification,
			templateData: notification.templateData,
		};

		try {
			if (notification.templateData) {
				const parsedData = JSON.parse(notification.templateData);
				formattedData.templateData = parsedData;
			}
		} catch (error) {
			console.error('Error parsing template data', error);
		}

		onEntityEvent.trigger(formattedData);
		invalidate([
			'entityEvents',
			notification.entity,
			notification.instanceId,
		]);

		invalidate(['myNotifications']);
	});

	return {
		onEntityEvent: onEntityEvent.on,
	};
}
