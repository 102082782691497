<template>
	<div
		class="max-w-full overflow-x-auto rounded-t-md border border-slate-200"
		:class="{
			'rounded-b-md': isPaginationDisabled,
		}"
	>
		<table class="h-full min-w-full divide-y divide-slate-200">
			<thead>
				<tr
					v-for="headerGroup in table.getHeaderGroups()"
					:key="headerGroup.id"
					class="relative z-20 divide-x divide-slate-200"
				>
					<th
						v-for="header in headerGroup.headers"
						:key="header.id"
						:colSpan="header.colSpan"
						class="px-2.5 text-left text-xs font-semibold uppercase text-slate-500"
						:style="{
							width:
								header.getSize() && header.getSize() > 0
									? header.getSize() + 'px'
									: '',
						}"
					>
						<div
							class="flex items-center justify-between gap-x-2 py-3"
						>
							<span
								class="flex grow items-center gap-x-1 whitespace-nowrap"
								:class="{
									'cursor-pointer':
										header.column.getCanSort(),
								}"
								@click="
									header.column.getCanSort() &&
										header.column.toggleSorting()
								"
							>
								<FlexRender
									v-if="!header.isPlaceholder"
									:render="header.column.columnDef.header"
									:props="header.getContext()"
								/>

								<Icon
									name="heroicons:arrow-up-20-solid"
									v-if="header.column.getIsSorted() === 'asc'"
									class="relative top-[-1px] inline-block size-4 text-slate-400"
								/>

								<Icon
									name="heroicons:arrow-down-20-solid"
									v-if="
										header.column.getIsSorted() === 'desc'
									"
									class="relative top-[-1px] inline-block size-4 text-slate-400"
								/>
							</span>

							<PopoverV2
								v-if="
									columnControl &&
									!header.column.columnDef?.meta
										?.hideProperties
								"
								v-model="popoverStates[header.id]"
								:origin="
									header.column.getIndex() === 0
										? 'left'
										: 'right'
								"
							>
								<template #button>
									<Button
										:icon="
											popoverStates[header.id]
												? 'heroicons:cog-6-tooth-solid'
												: 'heroicons:cog-6-tooth'
										"
										appearance="link"
										theme="light"
										size="xsmall"
										:aria-label="
											$s(
												'EntityGrid.Label.ColumnSettings'
											)
										"
									/>
								</template>

								<div class="flex flex-col gap-y-3 p-4 pt-5">
									<!-- Align -->
									<div class="flex items-center gap-6">
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s('EntityGrid.Label.Alignment')
											}}</label
										>
										<span
											class="relative z-0 inline-flex flex-1 rounded-md"
										>
											<button
												type="button"
												class="relative inline-flex items-center rounded-l-md border border-slate-300 bg-white px-2 py-1.5 font-medium text-slate-700 hover:bg-slate-50 focus:z-10"
												:class="{
													'!bg-slate-200':
														header.column.getProperties()
															.align === 'left' ||
														!header.column.getProperties()
															.align,
												}"
												@click="
													header.column.setProperties(
														{
															align: 'left',
														}
													)
												"
												:aria-label="
													$s(
														'EntityGrid.Label.AlignLeft'
													)
												"
												:title="
													$s(
														'EntityGrid.Label.AlignLeft'
													)
												"
											>
												<Icon
													name="heroicons:bars-3-bottom-left"
													class="text-slate size-4"
												/>
											</button>
											<button
												type="button"
												class="relative -ml-px inline-flex items-center border border-slate-300 bg-white px-2 py-1.5 font-medium text-slate-700 hover:bg-slate-50 focus:z-10"
												:class="{
													'!bg-slate-200':
														header.column.getProperties()
															.align === 'center',
												}"
												@click="
													header.column.setProperties(
														{
															align: 'center',
														}
													)
												"
												:aria-label="
													$s(
														'EntityGrid.Label.AlignCenter'
													)
												"
												:title="
													$s(
														'EntityGrid.Label.AlignCenter'
													)
												"
											>
												<Icon
													name="heroicons:bars-3"
													class="size-4 text-slate-500"
												/>
											</button>
											<button
												type="button"
												class="relative -ml-px inline-flex items-center rounded-r-md border border-slate-300 bg-white px-2 py-1.5 font-medium text-slate-700 hover:bg-slate-50 focus:z-10"
												:class="{
													'!bg-slate-200':
														header.column.getProperties()
															.align === 'right',
												}"
												@click="
													header.column.setProperties(
														{
															align: 'right',
														}
													)
												"
												:aria-label="
													$s(
														'EntityGrid.Label.AlignRight'
													)
												"
												:title="
													$s(
														'EntityGrid.Label.AlignRight'
													)
												"
											>
												<Icon
													name="heroicons:bars-3-bottom-right"
													class="size-4 text-slate-500"
												/>
											</button>
										</span>
									</div>

									<!-- Move -->
									<div class="flex items-center gap-6">
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s('EntityGrid.Label.Move')
											}}</label
										>
										<span
											class="relative z-0 inline-flex flex-1 rounded-md"
										>
											<button
												type="button"
												class="relative inline-flex items-center rounded-l-md border border-slate-300 bg-white px-2 py-1.5 font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary disabled:cursor-not-allowed disabled:bg-slate-200"
												@click="
													header.column.moveLeft()
												"
												:aria-label="
													$s(
														'EntityGrid.Label.MoveLeft'
													)
												"
												:title="
													$s(
														'EntityGrid.Label.MoveLeft'
													)
												"
											>
												<Icon
													name="heroicons:chevron-left"
													class="text-slate size-4"
												/>
											</button>

											<button
												type="button"
												class="relative -ml-px inline-flex items-center rounded-r-md border border-slate-300 bg-white px-2 py-1.5 font-medium text-slate-700 hover:bg-slate-50 focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary disabled:cursor-not-allowed disabled:bg-slate-200"
												@click="
													header.column.moveRight()
												"
												:aria-label="
													$s(
														'EntityGrid.Label.MoveRight'
													)
												"
												:title="
													$s(
														'EntityGrid.Label.MoveRight'
													)
												"
											>
												<Icon
													name="heroicons:chevron-right"
													class="size-4"
												/>
											</button>
										</span>
									</div>

									<div
										v-if="
											header.column.columnDef.__type ===
											'number'
										"
										class="flex items-center gap-6"
									>
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s('EntityGrid.Label.Sum')
											}}</label
										>
										<div>
											<FormKit
												type="toggle"
												:model-value="
													header.column.getProperties()
														.showSum
												"
												@update:model-value="
													header.column.setProperties(
														{
															showSum: $event,
														}
													)
												"
											/>
										</div>
									</div>
									<div
										v-if="
											header.column.columnDef.__type ===
											'number'
										"
										class="flex items-center gap-6"
									>
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s('EntityGrid.Label.Average')
											}}</label
										>
										<div>
											<FormKit
												type="toggle"
												:model-value="
													header.column.getProperties()
														.showAverage
												"
												@update:model-value="
													header.column.setProperties(
														{
															showAverage: $event,
														}
													)
												"
											/>
										</div>
									</div>
									<div
										v-if="
											header.column.columnDef.__type ===
											'number'
										"
										class="flex items-center gap-6"
									>
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s('EntityGrid.Label.Median')
											}}</label
										>
										<div>
											<FormKit
												type="toggle"
												:model-value="
													header.column.getProperties()
														.showMedian
												"
												@update:model-value="
													header.column.setProperties(
														{
															showMedian: $event,
														}
													)
												"
											/>
										</div>
									</div>

									<!-- Date format -->
									<div
										v-if="
											header.column.columnDef.__type ===
											'date'
										"
										class="flex items-center gap-6"
									>
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s(
													'EntityGrid.Label.DateFormat'
												)
											}}</label
										>
										<div>
											<FormKit
												type="select"
												:options="dateFormatOptions"
												:model-value="
													header.column.getProperties()
														.dateFormat
												"
												@update:model-value="
													header.column.setProperties(
														{
															dateFormat: $event,
														}
													)
												"
											/>
										</div>
									</div>

									<!-- Time format -->
									<div
										v-if="
											header.column.columnDef.__type ===
											'date'
										"
										class="flex items-center gap-6"
									>
										<label
											class="ax-form-label w-20 text-right"
											>{{
												$s(
													'EntityGrid.Label.TimeFormat'
												)
											}}</label
										>
										<div>
											<FormKit
												type="select"
												:options="timeFormatOptions"
												:model-value="
													header.column.getProperties()
														.timeFormat
												"
												@update:model-value="
													header.column.setProperties(
														{
															timeFormat: $event,
														}
													)
												"
											/>
										</div>
									</div>
								</div>

								<div
									class="flex justify-end rounded-b-md border-t border-slate-200 bg-slate-100 px-4 py-2"
								>
									<Button
										theme="light"
										size="xsmall"
										appearance="link"
										@click="header.column.remove()"
									>
										{{
											$s('EntityGrid.Button.RemoveColumn')
										}}
									</Button>
								</div>
							</PopoverV2>
						</div>
					</th>

					<th
						v-if="columnControl"
						class="sticky right-0 w-12 bg-slate-50"
					>
						<div
							class="-ml-0.5 flex h-full items-center justify-between border-l border-l-slate-200 pl-3 pr-3"
						>
							<PowerTableV3AddColumn :table />
						</div>
					</th>
				</tr>
			</thead>

			<tbody
				class="opacity-1 divide-y divide-slate-200 transition-opacity duration-200"
			>
				<tr
					v-for="(row, index) in table.getRowModel().rows"
					:key="row.id"
					class="h-full divide-x divide-slate-200 bg-white transition-colors"
					:class="{
						'!bg-slate-50 hover:bg-slate-100':
							index % 2 === 0 && table.getTableProperties().zebra,

						'cursor-pointer hover:bg-primary-50':
							!!table.getRowLink(row.original),
					}"
				>
					<slot
						v-if="$slots.row"
						name="row"
						:row="row.original"
						:cells="row.getVisibleCells()"
						:isLink="!!table.getRowLink(row.original)"
						:link="table.getRowLink(row.original)"
					/>
					<td
						v-else
						v-for="cell in row.getVisibleCells()"
						:key="cell.id"
						:class="{
							'w-10':
								cell.column.columnDef.__type === 'button' ||
								cell.column.columnDef.__type ===
									'buttonsColumn',
						}"
					>
						<PowerTableLinkWrapper
							:is-link="
								!!table.getRowLink(row.original) &&
								cell.column.columnDef.__type !== 'button' &&
								cell.column.columnDef.__type !== 'buttonsColumn'
							"
							:to="table.getRowLink(row.original)"
							class="block"
							:class="{
								'flex h-full min-h-12 items-center whitespace-nowrap px-2.5 py-1.5 text-sm font-medium text-slate-900 ': true,
								'justify-center':
									cell.column.getProperties().align ===
									'center',
								'justify-end':
									cell.column.getProperties().align ===
									'right',
							}"
						>
							<FlexRender
								:render="cell.column.columnDef.cell"
								:props="cell.getContext()"
							/>
						</PowerTableLinkWrapper>
					</td>
					<td v-if="columnControl" class="w-12"></td>
				</tr>
			</tbody>
			<tfoot v-if="hasColumnsWithAggregates">
				<tr class="divide-x divide-slate-200">
					<td
						v-for="column in table.getVisibleLeafColumns()"
						:key="column.id"
						class="bg-slate-50 py-3 pl-4 pr-3 text-left text-xs font-semibold uppercase text-slate-800"
						:class="{
							'text-left':
								column.getProperties().align === 'left',
							'text-center':
								column.getProperties().align === 'center',
							'text-right':
								column.getProperties().align === 'right',
						}"
					>
						<template v-if="isNumberColumn(column)">
							<div v-if="column.getProperties().showSum">
								<span class="text-slate-400">{{
									$s('EntityGrid.Label.Sum')
								}}</span>
								{{
									calculateSum(
										column,
										table.getRowModel().rows
									)
								}}
							</div>
							<div v-if="column.getProperties().showAverage">
								<span class="text-slate-400">{{
									$s('EntityGrid.Label.Average')
								}}</span>
								{{
									calculateAverage(
										column,
										table.getRowModel().rows
									)
								}}
							</div>
							<div v-if="column.getProperties().showMedian">
								<span class="text-slate-400">{{
									$s('EntityGrid.Label.Median')
								}}</span>
								{{
									calculateMedian(
										column,
										table.getRowModel().rows
									)
								}}
							</div>
						</template>
					</td>
				</tr>
			</tfoot>
		</table>
	</div>

	<div
		v-if="!isPaginationDisabled"
		class="flex items-center justify-between rounded-b-lg border border-t-0 border-slate-200 bg-slate-50 px-4 py-3 sm:px-6"
	>
		<div class="flex flex-1 items-center justify-between">
			<p class="text-sm text-slate-600" v-if="table.getRowCount()">
				{{ $s('EntityGrid.Label.Showing') }}
				{{ ' ' }}
				<span class="font-medium">{{
					table.getState().pagination.pageIndex *
						table.getState().pagination.pageSize +
					1
				}}</span>
				{{ ' ' }}
				-
				{{ ' ' }}
				<span class="font-medium">{{
					Math.min(
						(table.getState().pagination.pageIndex + 1) *
							table.getState().pagination.pageSize,
						table.getRowCount()
					)
				}}</span>
				{{ ' ' }}
				{{ $s('EntityGrid.Label.OfTotal') }}
				{{ ' ' }}
				<span class="font-medium">{{ table.getRowCount() }}</span>
				{{ ' ' }}
				{{ $s('EntityGrid.Label.Entries') }}
			</p>
			<p v-else class="text-sm text-slate-600">
				{{ $s('EntityGrid.Label.NoEntries') }}
			</p>

			<nav
				class="isolate inline-flex -space-x-px rounded-md shadow-sm"
				aria-label="Pagination"
			>
				<button
					:disabled="!table.getCanPreviousPage()"
					class="relative inline-flex items-center rounded-l-md px-2 py-2 text-slate-400 ring-1 ring-inset ring-slate-300 hover:bg-slate-50 focus:z-20 focus:outline-offset-0"
					:title="$s('Core.Button.Previous')"
					@click="table.previousPage()"
				>
					<span class="sr-only">{{
						$s('Core.Button.Previous')
					}}</span>
					<Icon
						name="heroicons:chevron-left-20-solid"
						class="h-5 w-5"
						aria-hidden="true"
					/>
				</button>

				<button
					:disabled="!table.getCanNextPage()"
					class="relative inline-flex items-center rounded-r-md px-2 py-2 text-slate-400 ring-1 ring-inset ring-slate-300 hover:bg-slate-50 focus:z-20 focus:outline-offset-0"
					:title="$s('Core.Button.Next')"
					@click="table.nextPage()"
				>
					<span class="sr-only">{{ $s('Core.Button.Next') }}</span>
					<Icon
						name="heroicons:chevron-right-20-solid"
						class="h-5 w-5"
						aria-hidden="true"
					/>
				</button>
			</nav>
		</div>
	</div>
</template>

<script setup lang="ts" generic="TData">
import { Column, FlexRender, Row, Table } from '@tanstack/vue-table';
import { DateFormat, TimeFormat } from '~/types/axos-api';

const props = defineProps<{
	table: Table<TData>;
	columnControl?: boolean;
}>();

const isPaginationDisabled = computed(
	() =>
		props.table.options.manualPagination &&
		props.table.getState().pagination?.pageSize === 0
);

const popoverStates = ref<Record<string, boolean>>({});

const hasColumnsWithAggregates = computed(() =>
	props.table
		.getAllColumns()
		.some(
			(column) =>
				(column.getProperties().showSum ||
					column.getProperties().showAverage ||
					column.getProperties().showMedian) &&
				isNumberColumn(column)
		)
);

function isNumberColumn(column: Column<TData, unknown>) {
	return column.columnDef.__type === 'number';
}

function calculateSum(column: Column<TData, unknown>, rows: Row<TData>[]) {
	const sum = rows.reduce((acc, row) => {
		const value = row.getValue(column.id);
		return acc + (typeof value === 'number' ? value : 0);
	}, 0);
	return sum.toFixed(2);
}

function calculateAverage(column: Column<TData, unknown>, rows: Row<TData>[]) {
	const sum = rows.reduce((acc, row) => {
		const value = row.getValue(column.id);
		return acc + (typeof value === 'number' ? value : 0);
	}, 0);
	return (sum / rows.length).toFixed(2);
}

function calculateMedian(column: Column<TData, unknown>, rows: Row<TData>[]) {
	const values = rows
		.map((row) => row.getValue(column.id))
		.filter((value) => typeof value === 'number')
		.sort((a, b) => a - b);

	const mid = Math.floor(values.length / 2);

	return values.length % 2 !== 0
		? values[mid].toFixed(2)
		: ((values[mid - 1] + values[mid]) / 2).toFixed(2);
}

const { formatDate, formatTime } = useDateUtils();

const dateFormatOptions = [
	{ value: null, label: $s('Core.Label.None') },
	{ value: DateFormat.Day, label: 'DD' },
	{ value: DateFormat.DayMonth, label: 'DD/MM' },
	{ value: DateFormat.DayMonthYear, label: 'DD/MM/YYYY' },
	{ value: DateFormat.Month, label: 'MM' },
	{ value: DateFormat.MonthYear, label: 'MM/YYYY' },
];

const timeFormatOptions = [
	{ value: null, label: $s('Core.Label.None') },
	{ value: TimeFormat.HourMinutes, label: 'HH:MM' },
	{ value: TimeFormat.HourMinutesSeconds, label: 'HH:MM:SS' },
];

function formatDateTimeValue(value: string, column: Column<TData, unknown>) {
	const properties = column.getProperties();
	const dateFormatted = formatDate(
		properties.dateFormat ?? DateFormat.DayMonthYear,
		value
	);
	const timeFormatted = formatTime(
		properties.timeFormat ?? TimeFormat.HourMinutes,
		value
	);
	return `${dateFormatted} ${timeFormatted}`;
}
</script>
