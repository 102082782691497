import { RowData } from '@tanstack/vue-table';
import { DateFormat, TimeFormat } from '~/types/axos-api';
import { PowerGridOptions } from './usePowerTable';
import { TablePropertiesState } from './usePowerTableFeatures';

const DEFAULT_COLUMN_PROPERTIES: ColumnProperties = {
	align: 'left',

	isNoWrap: false,
	showSum: false,
	showAverage: false,
	showMedian: false,
	dateFormat: DateFormat.DayMonthYear,
	timeFormat: TimeFormat.HourMinutes,
};

export default function <TData extends RowData>(
	options: PowerGridOptions<TData, any>
) {
	const { mergeDeep } = useObjectUtils();

	function getInitialPaginationState() {
		return {
			pageIndex:
				options?.initialState?.pagination?.pageIndex ??
				options?.manualPaginationState?.value?.pageIndex ??
				0,
			pageSize:
				options?.initialState?.pagination?.pageSize ??
				options?.manualPaginationState?.value?.pageSize ??
				25,
		};
	}

	function getInitialSortingState() {
		if (options?.manualSortingState?.value) {
			return [options.manualSortingState.value];
		}

		return options?.initialState?.sorting ?? [];
	}

	function getInitialColumnVisibilityState() {
		const columnIds = options.columns
			.filter((it) => it.id)
			.map((it) => it.id!);

		const initialColumnVisibility = columnIds.reduce(
			(acc, columnId) => ({
				...acc,
				[columnId]: true,
			}),
			{}
		);

		return mergeDeep(
			initialColumnVisibility,
			options?.initialState?.columnVisibility
		);
	}

	function getInitialColumnPropertiesState() {
		const columns = options.columns;
		const columnIds = options.columns.map((it) => it.id!);

		const initialColumnProperties = {
			...columnIds.reduce(
				(acc, columnId) => ({
					...acc,
					[columnId]: {
						powerTableProperties: {
							...DEFAULT_COLUMN_PROPERTIES,
							...columns.find((it) => it.id === columnId)
								?.properties,
						},
					},
				}),
				{}
			),
		};

		return mergeDeep(
			initialColumnProperties,
			options?.initialState?.columnProperties
		);
	}

	function getInitialColumnOrderState(): string[] {
		const definedOrder = options?.initialState?.columnOrder;

		const orderByColumns = options.columns
			.filter((it) => it.id)
			.map((it) => it.id!);

		if (definedOrder) {
			const missingColumns = orderByColumns.filter(
				(it) => !options.initialState?.columnOrder?.includes(it!)
			);

			return definedOrder;
		}

		return orderByColumns;
	}

	function getInitialTablePropertiesState(): TablePropertiesState<TData> {
		return {
			zebra: false,
			...options?.initialState?.tableProperties,
		};
	}

	function getInitialTableFiltersState(): TableFiltersState {
		return options?.filters ?? [];
	}

	function getInitialTableFilterVisibilityState(): TableFilterVisibilityState {
		return options?.initialState?.tableFilterVisibility ?? {};
	}

	function getInitialTableFilterValueState(): TableFilterValueState {
		return options?.initialState?.tableFilterValue ?? {};
	}

	return {
		getInitialPaginationState,
		getInitialSortingState,
		getInitialColumnVisibilityState,
		getInitialColumnPropertiesState,
		getInitialColumnOrderState,
		getInitialTablePropertiesState,
		getInitialTableFiltersState,
		getInitialTableFilterVisibilityState,
		getInitialTableFilterValueState,
	};
}
