<template>
	<!-- Search bar -->
	<div
		class="mx-auto hidden min-w-0 flex-1 justify-center pl-6 xs:flex sm:ml-16 sm:max-w-none sm:pl-0"
		:class="{
			'fixed left-9 right-3 top-0 z-10 !max-w-none sm:left-20 ':
				wideOnSmallScreens && isSmallScreen,
		}"
	>
		<div class="mx-auto flex flex-1 items-center">
			<div class="relative w-full">
				<TransitionRoot
					:show="open"
					as="template"
					@after-leave="query = ''"
					appear
				>
					<div
						class="absolute -top-7 left-1/2 z-10 mt-2 w-full max-w-xl -translate-x-1/2 sm:w-[80%] lg:w-2/3 lg:max-w-3xl xl:ml-7 xl:w-3/5"
						:class="{
							'!top-0 !w-full !max-w-none':
								wideOnSmallScreens && isSmallScreen,
						}"
					>
						<TransitionChild
							as="template"
							enter="ease-out duration-300"
							enter-from="opacity-0 scale-95"
							enter-to="opacity-100 scale-100"
							leave="ease-in duration-200"
							leave-from="opacity-100 scale-100"
							leave-to="opacity-0 scale-95"
							ref="menuRef"
						>
							<div
								class="w-full transform rounded-xl transition-all"
							>
								<Combobox
									v-model="selected"
									ref="menuRef"
									nullable
								>
									<div class="relative">
										<Icon
											name="heroicons:magnifying-glass"
											class="pointer-events-none absolute left-4 top-2.5 h-5 w-5 text-slate-500"
											aria-hidden="true"
										/>
										<ComboboxInput
											ref="inputRef"
											@click="
												showMenu = true;
												$nextTick(() => {
													inputRef.$el?.focus();
												});
											"
											class="h-10 w-full rounded-xl border-0 bg-transparent bg-white pl-11 pr-4 text-slate-700 placeholder:text-slate-500 focus-within:ring-2 focus-within:ring-primary-400 sm:text-sm"
											placeholder="Søk …"
											:display-value="() => query"
											@change="
												query = $event.target.value;
												showSearch = true;
											"
										/>

										<button
											v-if="query !== ''"
											@click="
												showSearch = false;
												showMenu = false;
												query = '';
											"
											class="absolute right-2 top-1.5 appearance-none p-1"
										>
											<Icon
												name="heroicons:x-mark"
												class="h-5 w-5 text-slate-500"
												aria-hidden="true"
											/>
										</button>
									</div>

									<div
										v-if="query !== ''"
										class="rounded-xl bg-white shadow-xl"
									>
										<div
											class="mt-px flex gap-x-2 px-4 pt-3"
										>
											<Chip
												value="Alle"
												:count="
													results?.estimatedTotalHits
												"
												:active="entityFilter === null"
												@click="
													entityFilter = null;
													showSearch = true;
												"
												size="small"
											/>
											<Chip
												v-for="chip of resultChips"
												:value="chip.label"
												:count="chip.count"
												:active="
													entityFilter === chip.key
												"
												@click="
													entityFilter = chip.key;
													showSearch = true;
												"
												size="small"
											/>
										</div>
										<ComboboxOptions
											static
											class="mt-2 max-h-[calc(100svh-130px)] overflow-y-auto rounded-b-xl bg-white pb-2"
										>
											<ComboboxOption
												v-for="record of hits"
												v-slot="{ active, selected }"
												:key="record.rowId"
												:value="record"
												:as="NuxtLink"
												class="search-hit"
											>
												<li
													class="mx-2 flex cursor-pointer items-center gap-x-2 rounded-md p-2"
													:class="{
														'bg-primary-100 text-slate-900':
															active,
														'bg-white text-slate-700':
															!active,
													}"
												>
													<ImageWithLoader
														v-if="record.imageUrl"
														:src="record.imageUrl"
														:height="40"
														:width="40"
														class="aspect-square h-10 w-10 shrink-0 rounded-full object-cover"
													/>

													<div
														v-if="!record.imageUrl"
														class="flex aspect-square h-10 w-10 shrink-0 items-center justify-center rounded-full bg-slate-100 text-slate-500"
													>
														<Icon
															name="heroicons:users"
															class="h-6 w-6"
														/>
													</div>

													<div
														class="flex flex-col leading-none"
													>
														<h3
															class="font-semibold"
															v-dompurify-html="
																$s(
																	record
																		?.formatted
																		?.title ||
																		record.title
																)
															"
														></h3>

														<p
															class="text-sm text-slate-600"
															v-dompurify-html="
																$s(
																	record
																		?.formatted
																		?.description ||
																		record.description
																)
															"
														></p>
													</div>
												</li>
											</ComboboxOption>
										</ComboboxOptions>
									</div>
								</Combobox>
							</div>
						</TransitionChild>
					</div>
				</TransitionRoot>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import {
	Combobox,
	ComboboxInput,
	ComboboxOption,
	ComboboxOptions,
	TransitionChild,
	TransitionRoot,
} from '@headlessui/vue';
import { onClickOutside, useMagicKeys, useFocus } from '@vueuse/core';

const NuxtLink = resolveComponent('NuxtLink');

const inputRef = ref<any>();

const router = useRouter();

const menuRef = ref<HTMLDivElement | null>();
onClickOutside(menuRef, () => {
	query.value = '';
	showMenu.value = false;
	showSearch.value = false;
	wideOnSmallScreens.value = false;
});

const open = ref(true);
const wideOnSmallScreens = ref(false);
const isSmallScreen = ref(window.innerWidth < 1024);
const query = ref('');
const queryDebounced = refDebounced(query, 250);
const selected = ref();

const { focused } = useFocus(inputRef);
watch(focused, (focused) => {
	if (focused) {
		wideOnSmallScreens.value = true;
	} else if (!open.value) {
		wideOnSmallScreens.value = false;
	}
});

watch(selected, (val) => {
	if (val && val.url) {
		router.push(val.url);
		showMenu.value = false;
		query.value = '';
		entityFilter.value = null;
	}
});

onMounted(() => {
	const resizeListener = () => {
		isSmallScreen.value = window.innerWidth < 1024;
	};

	window.addEventListener('resize', resizeListener);

	onUnmounted(() => {
		window.removeEventListener('resize', resizeListener);
	});
});

const entityFilter = ref<string | null>(null);
const { hits, results } = useGlobalSearch(queryDebounced, {
	entityFilter,
});

const resultChips = computed(() => {
	const facetResults = results.value?.facetDistribution;
	if (!facetResults) return [];

	return facetResults
		.map((facet) => ({
			label: $s(`${facet.entityName}.Entity.Name`),
			count: facet.totalHits,
			key: facet.entity,
		}))
		.filter((facet) => facet.count > 0);
});

const keys = useMagicKeys();

// Esc
const esc = keys['Escape'];
watch(esc, (v) => {
	if (v && showMenu.value) {
		query.value = '';
		showMenu.value = false;
		showSearch.value = false;
		inputRef.value?.$el.blur();
		wideOnSmallScreens.value = false;
	}
});

const showMenu = ref(false);
const showSearch = ref(false);
</script>

<style>
.search-hit mark {
	@apply bg-primary-200;
}
</style>
