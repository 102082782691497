import { AxosEntities } from '~/types/axos-api';
import Handlebars from 'handlebars';

export default function () {
	const query = gql`
		query getEntityUrls {
			entityUrls {
				id
				entity
				relativeUrl
			}
		}
	`;

	const { data, ...args } = useLegacyQuery(query, {
		key: ['entityUrls'],
		queryOptions: {
			staleTime: 1000 * 60 * 15, // 15 minutes
			refetchOnWindowFocus: false,
		},
	});

	const entityUrls = computed(() => data.value?.entityUrls ?? []);

	function getRelativeUrlByEntity(entity: AxosEntities) {
		const entityUrl = entityUrls.value?.find((r) => r.entity === entity);
		return entityUrl?.relativeUrl ?? '#';
	}

	function getRelativeUrlById(entityId: number) {
		const entityUrl = entityUrls.value?.find((r) => r.id === entityId);
		return entityUrl?.relativeUrl ?? '#';
	}

	function getEntityUrl(
		entityId: number | AxosEntities,
		instanceId: number,
		extraData: any = {}
	) {
		const entityUrl = entityUrls.value?.find((r) =>
			typeof entityId === 'number'
				? r.id === entityId
				: r.entity === entityId
		);
		if (!entityUrl) {
			return '#';
		}

		const compiledTemplate = Handlebars.compile(entityUrl.relativeUrl);

		const templateData = {
			...extraData,
			id: instanceId,
			instanceId: instanceId,
			rowId: instanceId,
		};

		return compiledTemplate(templateData);
	}

	// Helpers for entities
	function getEmployeeMeetingUrl(meetingId: number, employeeId: number) {
		return getEntityUrl(AxosEntities.EmployeeMeeting, meetingId, {
			employeeId,
		});
	}

	function getEmployeeUrl(employeeId: number) {
		return getEntityUrl(AxosEntities.Employee, employeeId);
	}

	function getAccountUrl(accountId: number) {
		return getEntityUrl(AxosEntities.Account, accountId);
	}

	function getContactUrl(contactId: number) {
		return getEntityUrl(AxosEntities.Contact, contactId);
	}

	function getAccountMeetingUrl(accountMeetingId: number) {
		return getEntityUrl(AxosEntities.AccountMeeting, accountMeetingId);
	}

	return {
		data: entityUrls,
		getRelativeUrlByEntity,
		getRelativeUrlById,
		getEntityUrl,

		getEmployeeMeetingUrl,
		getEmployeeUrl,
		getAccountUrl,
		getContactUrl,
		getAccountMeetingUrl,
	};
}
