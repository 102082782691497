<template>
	<span
		class="pointer-events-none absolute left-full top-1/2 z-20 ml-4 w-auto -translate-y-1/2 transform text-nowrap rounded bg-slate-700/90 px-2 pb-2 pt-1.5 text-xs leading-none text-white opacity-0 shadow-sm transition-opacity duration-300 group-hover:opacity-100 group-focus-visible:opacity-100"
	>
		<span
			class="absolute -left-[6px] top-1/2 h-0 w-0 -translate-y-1/2 transform border-b-[6px] border-r-[6px] border-t-[6px] border-b-transparent border-r-slate-700/90 border-t-transparent"
		></span>
		<slot></slot>
	</span>
</template>
