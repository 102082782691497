<template>
	<span class="loader text-primary"></span>
</template>
<style scoped>
.loader {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	position: relative;
	animation: rotate 1.5s linear infinite;
}
.loader::before {
	content: '';
	box-sizing: border-box;
	position: absolute;
	inset: 0px;
	border-radius: 50%;
	border: 3px solid currentColor;
	animation: prixClipFix 2.5s linear infinite;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes prixClipFix {
	0% {
		clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
	}
	25% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
	}
	50% {
		clip-path: polygon(
			50% 50%,
			0 0,
			100% 0,
			100% 100%,
			100% 100%,
			100% 100%
		);
	}
	75% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
	}
	100% {
		clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
	}
}
</style>
