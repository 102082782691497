<template>
	<div class="ax-button-wrapper">
		<component
			:is="link ? NuxtLink : 'button'"
			type="button"
			class="ax-button"
			:class="{
				/* THEMES */

				// Primary
				'bg-primary-500 text-white hover:bg-primary-600 focus-visible:ring-primary-600':
					theme === 'primary',

				// Secondary
				'bg-primary-50 text-slate-700 hover:bg-primary-100  hover:text-primary-950 focus-visible:ring-secondary-300':
					theme === 'secondary',

				// Success
				'bg-green-500 text-white hover:bg-green-600 focus-visible:ring-green-600':
					theme === 'success',

				// Danger
				'bg-red-100 text-red-800 hover:bg-red-200 hover:text-red-900 focus-visible:ring-red-400 ':
					theme === 'danger',

				// Neutral
				'border-slate-200 bg-slate-200 text-slate-800 hover:bg-slate-300 focus-visible:ring-slate-400':
					theme === 'neutral',

				// Warning
				'bg-yellow-200 text-yellow-800 hover:bg-yellow-300  focus-visible:ring-yellow-400 ':
					theme === 'warning',

				// Dark
				'bg-slate-700 text-slate-50 hover:bg-slate-800 focus-visible:ring-slate-700':
					theme === 'dark',

				// Light
				'border border-solid border-slate-200 bg-white text-slate-700 hover:bg-slate-100 hover:text-slate-800 focus-visible:ring-slate-400':
					theme === 'light',

				'!border-none !p-0': appearance === 'link',

				// Type: Link
				'!text-primary-800':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'primary',

				'!text-primary-500':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'secondary',

				'!text-green-500':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'success',

				'!text-red-600':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'danger',

				' !text-slate-500':
					(appearance === 'link' || appearance === 'ghost') &&
					theme === 'light',

				// Types: Link, ghost
				'flex items-center !bg-transparent !shadow-none hover:underline hover:shadow-none':
					appearance === 'link' || appearance === 'ghost',

				// Type: Default
				rounded: appearance === 'default' || !appearance,

				// Type: Outline
				'border border-solid border-slate-200': appearance === 'ghost',

				// Type: Rounded
				'rounded-full': appearance === 'rounded',

				// Size: Default
				'h-[2.2rem] px-3 py-1.5 text-xs sm:text-sm ':
					!size || size === 'default' || size === 'small',

				// Size: Xtra small
				'h-7 rounded px-2 py-1 text-xs': size === 'xsmall',

				// Size: Large
				'text-md px-4 py-2': size === 'large',

				// Size: X-Large
				'px-5 py-2.5 text-xl': size === 'xlarge',

				// Size: X-Large
				'px-6 py-3 text-3xl tracking-tight': size === 'xxlarge',

				// No padding
				'!px-0 !py-0': props?.noPadding,

				// Loading
				'pointer-events-none opacity-75': loading,

				// Full width
				'w-full justify-center': props?.fullWidth,
			}"
			:aria-label="ariaLabel"
			:title="ariaLabel"
			:disabled="loading"
			v-bind="$attrs"
		>
			<span v-if="icon && !loading" :class="iconClasses">
				<Icon :name="icon" class="color-current h-full w-full" />
			</span>

			<span v-if="loading" data-test="loading-icon">
				<Icon
					name="svg-spinners:ring-resize"
					class="color-current"
					:class="iconClasses"
				/>
			</span>

			<span
				v-if="$slots.default"
				:class="{ 'hidden xl:inline-block': hideTextOnSmallScreen }"
			>
				<slot> </slot>
			</span>
		</component>
	</div>
</template>

<script lang="ts">
export default {
	inheritAttrs: false,
};
</script>

<script setup lang="ts">
import type { ButtonProps } from '~/types/button';

const NuxtLink = resolveComponent('NuxtLink');

const props = withDefaults(defineProps<ButtonProps>(), {
	theme: 'primary',
});

const iconClasses = computed(() => ({
	'size-5':
		!props.size || props.iconSize === 'default' || props.size === 'small',
	'size-4': props.size === 'xsmall',
	'size-6': props.size === 'large' || props.iconSize === 'large',
	'order-2': props.iconPlacement === 'right',
}));
</script>
