<template>
	<li class="relative">
		<NuxtLink
			class="group block border-b border-slate-300 p-4 transition-colors hover:bg-primary-50"
			:class="!initialReadState ? 'bg-primary-50' : ''"
			:to="notificationUrl"
		>
			<div class="relative flex items-start space-x-3">
				<div class="relative">
					<Image
						v-if="notification?.responsibleImage"
						:src="notification?.responsibleImage"
						:height="40"
						:width="40"
						class="flex h-10 w-10 items-center justify-center rounded-full bg-slate-400"
					/>
				</div>
				<div class="min-w-0 flex-1 leading-3">
					<div>
						<div class="text-sm">
							<a class="font-semibold">
								{{ notification.responsibleName ?? 'System' }}
							</a>
						</div>
						<p class="text-xs text-slate-400">
							{{ localizedTimeAgo }}
						</p>
					</div>

					<div
						class="mt-1 text-sm text-slate-700"
						v-dompurify-html="
							$r(
								$s(
									notification.resourceId,
									ResourceContext.FrontpageAndNotification
								),
								notification.data
							)
						"
					></div>
				</div>
			</div>
		</NuxtLink>
	</li>
</template>

<script setup lang="ts">
import { NotificationDto } from '~/types/axos-api';

const props = defineProps<{
	isLast?: Boolean;
	notification: NotificationDto;
}>();

const { localizedTimeAgo } = useLocalizedTimeAgo(
	new Date(props.notification.createdOn)
);

const { $r } = useTemplate();
const { getEntityUrl } = useEntityUrl();

const initialReadState = props.notification.isRead;
const timeAgo = useTimeAgo(new Date(props.notification.createdOn));

const notificationUrl = getEntityUrl(
	props.notification.entityId,
	props.notification.instanceId
);
</script>
