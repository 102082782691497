// Default icon for modules

// HR
export const ICON_HR = 'heroicons:user-group';
export const ICON_EMPLOYEE = 'heroicons:users';
export const ICON_ABSENCE = 'heroicons:calendar-days';
export const ICON_ABSENCE_CALENDAR = 'heroicons:calendar-days';
export const ICON_ABSENCE_APPROVAL = 'heroicons:check-circle';
export const ICON_ABSENCE_BALANCES = 'ph:scales-fill';
export const ICON_EMPLOYMENT = 'heroicons:briefcase';
export const ICON_ALLERGIES = 'healthicons:allergies-outline';
export const ICON_USER = 'heroicons:user';
export const ICON_INFO = 'heroicons:information-circle';
export const ICON_EDUCATION = 'heroicons:academic-cap';
export const ICON_COMPETENCE = 'heroicons:trophy';
export const ICON_CERTIFICATE = 'heroicons:identification';
export const ICON_ADDRESS = 'heroicons:map-pin';
export const ICON_FAMILY = 'heroicons:heart';
export const ICON_DEVICE = 'heroicons:computer-desktop';
export const ICON_INSURANCE = 'heroicons:shield-check';
export const ICON_BENEFIT = 'heroicons:star';
export const ICON_DEPARTMENT = 'heroicons:building-storefront';
export const ICON_OFFICE = 'heroicons:building-library';
export const ICON_TEAM = 'heroicons:users';
export const ICON_NUMBERING = 'heroicons:hashtag';
export const ICON_OKR = 'ph:target';

// CRM
export const ICON_CRM = 'heroicons:briefcase';
export const ICON_CONTACT = 'heroicons:user-circle';
export const ICON_ACCOUNT = 'heroicons:building-office-2';
export const ICON_MEETING = 'heroicons:calendar-days';
export const ICON_SOCIAL = 'heroicons:chat-bubble-left-right';

// Organisation
export const ICON_ORGANISATION = 'heroicons:building-office-2';

// Idea
export const ICON_IDEA = 'heroicons:megaphone';

// Core
export const ICON_ADMIN = 'heroicons:cog';
export const ICON_HISTORY = 'heroicons:clock';

// Back
export const ICON_BACK = 'heroicons:chevron-left-20-solid';
