import { ResourceContext, ResourceDto } from '~/types/axos-api';

const resourcesMap = new Map<string, ResourceDto>();
const languageId = ref(1);

const showMissingResources =
	localStorage.getItem('showMissingResources') === 'true';
const showExistingResources =
	localStorage.getItem('showExistingResources') === 'true';

function getResourceItem(
	key: string,
	languageId: number,
	context?: ResourceContext
) {
	const resource = resourcesMap.get(key);

	let resourceItem = resource?.resourceItems.find(
		(ri) => ri.languageId === languageId
	);

	if (context) {
		const contextResourceItem = resource?.resourceItems.find(
			(ri) => ri.languageId === languageId && ri.context === context
		);

		if (contextResourceItem) {
			resourceItem = contextResourceItem;
		}
	}

	if (resourceItem) {
		return resourceItem;
	}

	// Default to first item with values
	const firstResourceItem = resource?.resourceItems.find(
		(ri) => ri.valueSingular || ri.valuePlural
	);

	if (firstResourceItem) {
		return firstResourceItem;
	}

	return null;
}

export default function useLocalization() {
	function setResourcesData(data: ResourceDto[]) {
		data.forEach((resource) => {
			resourcesMap.set(resource.resourceId, resource);
		});
	}

	function setLanguageId(id: number) {
		languageId.value = id;
	}

	const { currentLanguageId } = useAppLanguage();

	function getLocalizedResourceItem(key: string, context?: ResourceContext) {
		return getResourceItem(key, currentLanguageId.value, context);
	}

	/**
	 * Gets localized singular resource
	 * @param key Resource key
	 * @returns
	 */
	const getSingular = (key?: string, context?: ResourceContext) => {
		if (!key) {
			return '';
		}

		const resourceItem = getLocalizedResourceItem(key, context);
		if (!resourceItem?.valueSingular) {
			if (showMissingResources) {
				return `❌ ${key}`;
			}
			return key;
		}

		if (showExistingResources) {
			return `✅ ${resourceItem.valueSingular}`;
		}

		return resourceItem.valueSingular;
	};

	/**
	 * Gets localized singular resource
	 * @param key Resource key
	 * @returns
	 */
	const getPlural = (key?: string, context?: ResourceContext) => {
		if (!key) {
			return '';
		}

		const resourceItem = getLocalizedResourceItem(key, context);
		if (resourceItem?.valuePlural) {
			if (showExistingResources) {
				return `✅ ${resourceItem.valuePlural}`;
			}

			return resourceItem.valuePlural;
		}

		// If plural is not defined, fallback to singular
		return getSingular(key);
	};

	return {
		setResourcesData,
		setLanguageId,
		getSingular,
		getPlural,
		$s: getSingular,
		$p: getPlural,
		$sl: (key?: string) => getSingular(key ?? '')?.toLowerCase(),
		$pl: (key?: string) => getPlural(key ?? '')?.toLowerCase(),
		getResourceItem: getLocalizedResourceItem,
	};
}

export function $s(key?: string, context?: ResourceContext) {
	if (!key) {
		return '';
	}

	const resourceItem = getResourceItem(key, languageId.value, context);
	if (!resourceItem?.valueSingular) {
		if (showMissingResources) {
			return `❌ ${key}`;
		}
		return key;
	}

	if (showExistingResources) {
		return `✅ ${resourceItem.valueSingular}`;
	}

	return resourceItem.valueSingular;
}

export function $p(key?: string, context?: ResourceContext) {
	if (!key) {
		return '';
	}

	const resourceItem = getResourceItem(key, languageId.value, context);
	if (resourceItem?.valuePlural) {
		if (showExistingResources) {
			return `✅ ${resourceItem.valuePlural}`;
		}

		return resourceItem.valuePlural;
	}

	// If plural is not defined, fallback to singular
	return $s(key);
}

export function $sl(key?: string) {
	return $s(key)?.toLowerCase();
}

export function $pl(key?: string) {
	return $p(key)?.toLowerCase();
}
